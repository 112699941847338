import React from 'react'

function Socialmedia() {
  return (
<>




<style type="text/css" data-type="vc_shortcodes-custom-css">{`
        .vc_custom_1672420766299 {
            background-image: url(https://adsdad.com/wp-content/uploads/2022/12/ppcbg-new.jpg?id=171) !important;
        }
    `}</style>

<div id="content" className="site-content">
            <div className="container">
                <div className="row">

                    <section id="primary" className="content-area col-sm-12">
                        <div id="main" className="site-main" role="main">


                            <article id="post-302" className="post-302 page type-page status-publish hentry">

                                <div className="entry-content">
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-ppc vc_custom_1672420766299 vc_row-has-fill vc_row-no-padding" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-8">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html ppc-area-hd">
                                                                        <div className="wpb_wrapper">
                                                                            <h1>Leading Social Media Marketing Agency
                                                                            </h1>
                                                                            <h2>BUILD YOUR BRAND & GET MORE
                                                                                CONVERSIONS WITH OUR SOCIAL MEDIA
                                                                                MARKETING SERVICES </h2>
                                                                            <p>We Boost your Social Media Ads Return on
                                                                                Investment (ROI) through in-depth Ad
                                                                                Campaign Optimization and Find those
                                                                                Customers who are interested in your
                                                                                Product or Services – Get timely and
                                                                                transparent campaign reports.</p>
                                                                            <div className="ftp-top">
                                                                                <ul className="crtified">
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"/></noscript>
                                                                                    </li>
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"/></noscript>
                                                                                    </li>
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"/></noscript>
                                                                                    </li>
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"/></noscript>
                                                                                    </li>
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"/></noscript>
                                                                                    </li>
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpcf7 js" id="wpcf7-f979-p302-o3" lang="en-US" dir="ltr">
                                                                        <div className="screen-reader-response">
                                                                            <p role="status" aria-live="polite" aria-atomic="true" />
                                                                            <ul />
                                                                        </div>
                                                                        <form action="/social-media-marketing-agency-delhi/#wpcf7-f979-p302-o3" method="post" className="wpcf7-form init" aria-label="Contact form" noValidate data-status="init">
                                                                            <div style={{ display: "none" }}>
                                                                                <input type="hidden" name="_wpcf7" value="979" />
                                                                                <input type="hidden" name="_wpcf7_version" value="5.9.8" />
                                                                                <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                                                                <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f979-p302-o3" />
                                                                                <input type="hidden" name="_wpcf7_container_post" value="302" />
                                                                                <input type="hidden" name="_wpcf7_posted_data_hash"  />
                                                                            </div>
                                                                            <div className="ppcform">
                                                                                <h5>Build Brand
                                                                                </h5>
                                                                                <h6>with Social Media Marketing
                                                                                </h6>
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="fld">
                                                                                            <p><span className="wpcf7-form-control-wrap" data-name="text-659"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" placeholder="Full Name"  type="text" name="text-659" fdprocessedid="gmz3oh" /></span><i className="fas fa-user" />
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="fld">
                                                                                            <p><span className="wpcf7-form-control-wrap" data-name="email-722"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control" aria-required="true" aria-invalid="false" placeholder="Email Address"  type="email" name="email-722" fdprocessedid="lhglet" /></span><i className="fas fa-envelope" />
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="fld">
                                                                                            <p><span className="wpcf7-form-control-wrap" data-name="tel-659"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control" aria-required="true" aria-invalid="false" placeholder="Phone No"  type="tel" name="tel-659" fdprocessedid="wcjt0x" /></span><i className="fas fa-phone-alt" />
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="fld but">
                                                                                            <p><input className="wpcf7-form-control wpcf7-submit has-spinner btn btn-primary" type="submit" value="Get Your Free Proposal" fdprocessedid="mslydp" /><span className="wpcf7-spinner" /><i className="fa fa-angle-right" aria-hidden="true" />
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div><input type="hidden" className="wpcf7-pum"  />
                                                                            <div className="wpcf7-response-output" aria-hidden="true" />
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            { /* Widget Shortcode */ }
                                                                            <div id="custom_html-2" className="widget_text widget widget_custom_html widget-shortcode area-arbitrary ">
                                                                                <div className="textwidget custom-html-widget">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3">
                                                                                            <div className="box-area">
                                                                                                <p>We Have Over</p>
                                                                                                <h5>3+</h5>
                                                                                                <span>Years of
                                                                                                    Experience</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="box-area">
                                                                                                <p>Expertise In</p>
                                                                                                <h5>12+</h5>
                                                                                                <span>Technologies</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="box-area">
                                                                                                <p>We Have Done</p>
                                                                                                <h5>2K+</h5>
                                                                                                <span>Projects &
                                                                                                    Counting</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="box-area">
                                                                                                <p>Clients In</p>
                                                                                                <h5>28+</h5>
                                                                                                <span>Countries</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>{ /* /Widget Shortcode */ }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-top-bottm" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Introducing Our Comprehensive Social
                                                                                Media Marketing Solution</h4>
                                                                            <p><span data-preserver-spaces="true">Our
                                                                                    smart and quick solution to all your
                                                                                    social problems is here! Your
                                                                                    one-stop solution htmlFor all your
                                                                                    social media management.</span></p>
                                                                            <p>• 1-1 Consultation (Audit &
                                                                                Recommendations)</p>
                                                                            <p>• Integrate with Social Media Platforms
                                                                            </p>
                                                                            <p>• Content Creation Calendar</p>
                                                                            <p>• Customized High-Quality Graphics</p>
                                                                            <p>• Social Media Advertising</p>
                                                                            <p>• 24×7 Responsive Team</p>
                                                                            <p>• Dedicated Account Manager</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  top-img">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img fetchpriority="high" decoding="async" width="1080" height="802" src="https://adsdad.com/wp-content/uploads/2024/02/Social_Media_Marketing_GIF_Raw_v006B.gif" className="vc_single_image-img attachment-full" alt="" title="Social_Media_Marketing_GIF_Raw_v006B" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-50" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  boxraea">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img decoding="async" width="2425" height="1617" data-src="https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110.jpg" className="vc_single_image-img attachment-full lazyload" alt="" title="343625-PACYV5-110" data-srcset="https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110.jpg 2425w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-1024x683.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-768x512.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-1536x1024.jpg 1536w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-2048x1366.jpg 2048w" data-sizes="(max-width: 2425px) 100vw, 2425px" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" style={{ SmushPlaceholderWidth: 2425, SmushPlaceholderAspectRatio: "2425/1617" }} /><noscript><img
                                                                                        decoding="async" width="2425"
                                                                                        height="1617"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110.jpg"
                                                                                        className="vc_single_image-img attachment-full"
                                                                                        alt="" title="343625-PACYV5-110"
                                                                                        srcSet="https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110.jpg 2425w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-1024x683.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-768x512.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-1536x1024.jpg 1536w, https://adsdad.com/wp-content/uploads/2023/02/343625-PACYV5-110-2048x1366.jpg 2048w"
                                                                                        sizes="(max-width: 2425px) 100vw, 2425px" /></noscript>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Get customization of each strategy htmlFor
                                                                                your type of audience.</h4>
                                                                            <p>Every brand outlook is different, so
                                                                                their social media strategy is, but it
                                                                                is essential to showcase the brand story
                                                                                in each post and address your followers
                                                                                and target audience. Let’s walk one by
                                                                                one on each social media platform via
                                                                                your social media experts whose basket
                                                                                of ideas is packed htmlFor your brand. We
                                                                                focused on organic and advertising
                                                                                followers via different means in the
                                                                                digital world.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-50" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>It’s time to break the game with
                                                                                best-in-class graphics and visual
                                                                                content.</h4>
                                                                            <p><span style={{ fontWeight: "400" }}>Reach the
                                                                                    maximizing audience where everyone
                                                                                    is on social media waiting htmlFor
                                                                                    different
                                                                                    strategies. </span><span style={{ fontWeight: "400" }}>The goal
                                                                                    is to try everything in this new era
                                                                                    of technology with unlimited graphic
                                                                                    designing and visual content and
                                                                                    stand out in the competition.</span>
                                                                            </p>

                                                                        </div>
                                                                    </div>

                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="text-green"><span>78%</span>
                                                                                <p>of consumers state their purchases
                                                                                    are influenced by posts on the
                                                                                    social media pages of brands.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  top-img boxraea">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img decoding="async" width="1160" height="774" data-src="https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit.jpg" className="vc_single_image-img attachment-full lazyload" alt="" title="social-media-branding-kit" data-srcset="https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit.jpg 1160w, https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit-1024x683.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit-768x512.jpg 768w" data-sizes="(max-width: 1160px) 100vw, 1160px" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" style={{ SmushPlaceholderWidth: 1160, SmushPlaceholderAspectRatio: "1160/774" }} /><noscript><img
                                                                                        decoding="async" width="1160"
                                                                                        height="774"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit.jpg"
                                                                                        className="vc_single_image-img attachment-full"
                                                                                        alt=""
                                                                                        title="social-media-branding-kit"
                                                                                        srcSet="https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit.jpg 1160w, https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit-1024x683.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/social-media-branding-kit-768x512.jpg 768w"
                                                                                        sizes="(max-width: 1160px) 100vw, 1160px" /></noscript>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-50" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  boxraea">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img loading="lazy" decoding="async" width="2000" height="1335" data-src="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580.jpeg" className="vc_single_image-img attachment-full lazyload" alt="" title="Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580" data-srcset="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580.jpeg 2000w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580-300x200.jpeg 300w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580-1024x684.jpeg 1024w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580-768x513.jpeg 768w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580-1536x1025.jpeg 1536w" data-sizes="(max-width: 2000px) 100vw, 2000px" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" style={{ SmushPlaceholderWidth: 2000, SmushPlaceholderAspectRatio: "2000/1335" }} /><noscript><img
                                                                                        loading="lazy" decoding="async"
                                                                                        width="2000" height="1335"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580.jpeg"
                                                                                        className="vc_single_image-img attachment-full"
                                                                                        alt=""
                                                                                        title="Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580"
                                                                                        srcSet="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580.jpeg 2000w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580-300x200.jpeg 300w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580-1024x684.jpeg 1024w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580-768x513.jpeg 768w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Apps-Logotypes-Printed-on-a-Cubes-1173494845_3866x2580-1536x1025.jpeg 1536w"
                                                                                        sizes="(max-width: 2000px) 100vw, 2000px" /></noscript>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Increase your presence on social media
                                                                                platforms.</h4>
                                                                            <p><span style={{ fontWeight: "400" }}>Active
                                                                                    participation is the best strategy
                                                                                    to broaden your audience’s organic
                                                                                    reach and impressions and ensure
                                                                                    they are heard. Creating
                                                                                    eye-catching accounts is just one
                                                                                    aspect of our Social Media
                                                                                    Management Solutions. We also
                                                                                    provide substantial interaction to
                                                                                    place your brand at the center of
                                                                                    the discourse. We’ll demonstrate to
                                                                                    your followers that you talk and
                                                                                    walk with them! A properly executed
                                                                                    engagement strategy will
                                                                                    significantly improve your brand’s
                                                                                    reputation, organic reach, customer
                                                                                    service, and support.</span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid media-managment vc_custom_1676573849478 vc_row-has-fill" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  title-faq wht">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Benefits of Social Media Management</h4>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid mm vc_custom_1676573902626 vc_row-has-fill" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="right-bottom wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/build-brand-awareness.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 69, SmushPlaceholderAspectRatio: "69/69" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/build-brand-awareness.png" /></noscript><br />
                                                                                <span className="tit">Build Brand
                                                                                    Awareness</span><br />
                                                                                Engage with your target audience on the
                                                                                social media platforms they go to most
                                                                                often.
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="right-bottom wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/reputation-management.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 65, SmushPlaceholderAspectRatio: "65/65" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/reputation-management.png" /></noscript><br />
                                                                                <span className="tit">Reputation
                                                                                    Management</span><br />
                                                                                Engage with your target audience on the
                                                                                social media platforms they go to most
                                                                                often.
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="onlybottom wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/expand-your-influence.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 63, SmushPlaceholderAspectRatio: "63/63" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/expand-your-influence.png" /></noscript><br />
                                                                                <span className="tit">Expand Your
                                                                                    Influence</span><br />
                                                                                Engage with your target audience on the
                                                                                social media platforms they go to most
                                                                                often.
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="left-bottom wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/showcase-your-expertise.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 59, SmushPlaceholderAspectRatio: "59/59" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/showcase-your-expertise.png" /></noscript><br />
                                                                                <span className="tit">Showcase Your
                                                                                    Expertise</span>
                                                                            </p>
                                                                            <p>Engage with your target audience on the
                                                                                social media platforms they go to most
                                                                                often.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="onlyright wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/be-in-the-conversation.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 66, SmushPlaceholderAspectRatio: "66/66" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/be-in-the-conversation.png" /></noscript><br />
                                                                                <span className="tit">Be In The
                                                                                    Conversation</span>
                                                                            </p>
                                                                            <p>Engage with your target audience on the
                                                                                social media platforms they go to most
                                                                                often.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="onlyright wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/drive-traffic-to-your-website.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 61, SmushPlaceholderAspectRatio: "61/53" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/drive-traffic-to-your-website.png" /></noscript><br />
                                                                                <span className="tit">Drive Traffic To Your
                                                                                    Website</span>
                                                                            </p>
                                                                            <p>Engage with your target audience on the
                                                                                social media platforms they go to most
                                                                                often.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="on-padd wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/build-a-loyal-following.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 56, SmushPlaceholderAspectRatio: "56/56" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/build-a-loyal-following.png" /></noscript><br />
                                                                                <span className="tit">Build A Loyal
                                                                                    Following<br />
                                                                                </span>
                                                                            </p>
                                                                            <p>Engage with your target audience on the
                                                                                social media platforms they go to most
                                                                                often.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="botmleft wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/understand-your-growth.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 54, SmushPlaceholderAspectRatio: "54/54" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/understand-your-growth.png" /></noscript><br />
                                                                                <span className="tit">Understand Your
                                                                                    Growth</span>
                                                                            </p>
                                                                            <p>Engage with your target audience on the
                                                                                social media platforms they go to most
                                                                                often.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-100" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  addverting">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Advertise your business on</h4>

                                                                        </div>
                                                                    </div>

                                                                    <div className="wpb_text_column wpb_content_element  addverting">
                                                                        <div className="wpb_wrapper">
                                                                            { /* Widget Shortcode */ }
                                                                            <div id="custom_html-3" className="widget_text widget widget_custom_html widget-shortcode area-arbitrary ">
                                                                                <div className="textwidget custom-html-widget">
                                                                                    <ul className="advertising">
                                                                                        <li className="puls"><a href="#"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/reddit-ads.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 162, SmushPlaceholderAspectRatio: "162/54" }} /><noscript><img
                                                                                                        decoding="async"
                                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/reddit-ads.png"/></noscript></a>
                                                                                        </li>
                                                                                        <li className="puls"><a href="#"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/pinterest-ads.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 169, SmushPlaceholderAspectRatio: "169/43" }} /><noscript><img
                                                                                                        decoding="async"
                                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/pinterest-ads.png"/></noscript></a>
                                                                                        </li>
                                                                                        <li className="puls"><a href="#"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/linkedin-ads.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 163, SmushPlaceholderAspectRatio: "163/43" }} /><noscript><img
                                                                                                        decoding="async"
                                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/linkedin-ads.png"/></noscript></a>
                                                                                        </li>
                                                                                        <li className="puls"><a href="#"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/instagram-ads.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 171, SmushPlaceholderAspectRatio: "171/48" }} /><noscript><img
                                                                                                        decoding="async"
                                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/instagram-ads.png"/></noscript></a>
                                                                                        </li>
                                                                                        <li className="puls"><a href="#"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/facebook-ads.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 172, SmushPlaceholderAspectRatio: "172/34" }} /><noscript><img
                                                                                                        decoding="async"
                                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/facebook-ads.png"/></noscript></a>
                                                                                        </li>
                                                                                        <li className="puls"><a href="#"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/twitter-ads.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 181, SmushPlaceholderAspectRatio: "181/34" }} /><noscript><img
                                                                                                        decoding="async"
                                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/twitter-ads.png"/></noscript></a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>{ /* /Widget Shortcode */ }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid bg-color-grey" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="addverting">
                                                                                <h4>Remarkable results htmlFor businesses
                                                                                </h4>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="business-portfolio">
                                                                                <div className="linkareaa"><a href="#">Clark
                                                                                        Filters Direct</a></div>
                                                                                        <div className="platform-img"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/img-clark-filter.png" src="https://adsdad.com/wp-content/uploads/2023/01/img-clark-filter.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 600, SmushPlaceholderAspectRatio: "600/395" }} /></div>
                                                                                <ul className="case-result-list list-unstyled">
                                                                                    <li><strong className="font-weight-bold h3">633%</strong><strong className="d-block font-weight-normal">Increase
                                                                                            in RPC</strong></li>
                                                                                    <li><strong className="font-weight-bold h3">418%</strong><strong className="d-block font-weight-normal">Increase
                                                                                            in ROAS</strong></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="business-portfolio">
                                                                                <div className="linkareaa"><a href="#">Clark
                                                                                        Filters Direct</a></div>
                                                                                        <div className="platform-img"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/img-clark-filter.png" src="https://adsdad.com/wp-content/uploads/2023/01/img-clark-filter.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 600, SmushPlaceholderAspectRatio: "600/395" }} /></div>
                                                                                <ul className="case-result-list list-unstyled">
                                                                                    <li><strong className="font-weight-bold h3">633%</strong><strong className="d-block font-weight-normal">Increase
                                                                                            in RPC</strong></li>
                                                                                    <li><strong className="font-weight-bold h3">418%</strong><strong className="d-block font-weight-normal">Increase
                                                                                            in ROAS</strong></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid faq-bottom-padding" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html title-faq">
                                                                        <div className="wpb_wrapper">
                                                                            <h3>Questions to Ask When Hiring a Social
                                                                                Media Marketing Agency</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>What is Social Media Marketing?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>Social
                                                                                    media marketing is using social
                                                                                    media platforms and websites to
                                                                                    promote a product or service. It
                                                                                    includes posting text and image
                                                                                    updates, videos, and other content
                                                                                    that drives audience engagement, as
                                                                                    well as paid social media
                                                                                    advertising. Social media marketing
                                                                                    aims to create content that users
                                                                                    will share with their social network
                                                                                    to help a company increase brand
                                                                                    exposure and broaden customer
                                                                                    reach.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>How does Social Media Marketing work?
                                                                            </h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>Social
                                                                                    media marketing is one of the most
                                                                                    important aspects of modern-day
                                                                                    marketing. It involves leveraging
                                                                                    various platforms to engage with
                                                                                    customers, drive website traffic,
                                                                                    and ultimately increase sales.
                                                                                    Businesses can gain an edge in their
                                                                                    respective industries by utilizing
                                                                                    the right strategies and
                                                                                    techniques.</span></p>
                                                                            <p><span style={{ fontWeight: "400" }}>Social
                                                                                    media marketing involves creating
                                                                                    content that appeals to the target
                                                                                    audience while also keeping in mind
                                                                                    the goals set out by the company.
                                                                                    This content is then shared across
                                                                                    social media platforms such as
                                                                                    Facebook, Twitter, Instagram, and
                                                                                    YouTube to reach a larger audience.
                                                                                    Through this type of marketing,
                                                                                    businesses can create relationships
                                                                                    with potential customers, build
                                                                                    brand awareness and trust among
                                                                                    users, and create opportunities htmlFor
                                                                                    conversions.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>Why is social media marketing necessary
                                                                                htmlFor businesses?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>Social
                                                                                    media marketing is an indispensable
                                                                                    tool htmlFor businesses today. It helps
                                                                                    them reach a wider audience, build
                                                                                    customer relationships, and promote
                                                                                    their products or services most
                                                                                    efficiently. With social media,
                                                                                    businesses can target their
                                                                                    campaigns to a specific demographic
                                                                                    and create engaging content that
                                                                                    resonates with their target
                                                                                    audience. Through social media,
                                                                                    businesses can also measure the
                                                                                    success of their campaigns through
                                                                                    analytics tools and adjust their
                                                                                    strategies accordingly.
                                                                                    Additionally, businesses use social
                                                                                    media to build brand recognition,
                                                                                    boost website traffic, and increase
                                                                                    conversions.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>What services are included in social
                                                                                media marketing?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <ol>
                                                                                <li><span style={{ fontWeight: "400" }}>
                                                                                        Content creation</span></li>
                                                                                <li><span style={{ fontWeight: "400" }}>
                                                                                        Social Media
                                                                                        Management </span></li>
                                                                                <li><span style={{ fontWeight: "400" }}>
                                                                                        Social media
                                                                                        Advertising </span></li>
                                                                                <li><span style={{ fontWeight: "400" }}>
                                                                                        Competitor Analysis</span></li>
                                                                                <li><span style={{ fontWeight: "400" }}>
                                                                                        Influencer
                                                                                        Marketing </span></li>
                                                                                <li><span style={{ fontWeight: "400" }}>
                                                                                        Social Media
                                                                                        Listening </span></li>
                                                                                <li><span style={{ fontWeight: "400" }}>
                                                                                        Reporting and Analytics</span>
                                                                                </li>
                                                                                <li><span style={{ fontWeight: "400" }}>
                                                                                        Reputation Management</span>
                                                                                </li>
                                                                            </ol>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>How much do Social Media Marketing
                                                                                services cost in India?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>The cost
                                                                                    of social media marketing services
                                                                                    in India depends on various factors,
                                                                                    including the size and scope of the
                                                                                    project, the number of platforms to
                                                                                    be managed, the type of content to
                                                                                    be created, and the level of
                                                                                    engagement required. Generally, the
                                                                                    cost of social media marketing
                                                                                    services in India range from as low
                                                                                    as ₹2,000 per month to as high as
                                                                                    ₹50,000 per month.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>Why Should I hire Social Media Marketing
                                                                                Agency?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>Hiring a
                                                                                    social media marketing agency can
                                                                                    help you gain visibility, increase
                                                                                    brand awareness and reach a larger
                                                                                    audience. A social media agency can
                                                                                    help you create a comprehensive
                                                                                    strategy, create eye-catching
                                                                                    content, analyze data, and measure
                                                                                    the success of your campaigns. A
                                                                                    social media agency also has access
                                                                                    to the most up-to-date resources and
                                                                                    technology, ensuring that your
                                                                                    campaigns are successful and
                                                                                    effective. With their expertise, you
                                                                                    can save time and money while
                                                                                    achieving the best possible
                                                                                    results.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>Why Choose NavUday Solution Digital Social Media
                                                                                Marketing Services?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>NavUday Solution
                                                                                    Digital Social Media Marketing
                                                                                    Services offers a comprehensive
                                                                                    suite of services tailored to meet
                                                                                    each client’s unique needs. Our
                                                                                    services include custom content
                                                                                    creation, targeted advertising
                                                                                    campaigns, analytics and reporting,
                                                                                    and reputation management. We also
                                                                                    offer various social media marketing
                                                                                    and management services, including
                                                                                    designing and implementing
                                                                                    successful campaigns, creating
                                                                                    content, and managing accounts on
                                                                                    all major social networks. Our team
                                                                                    of experts is highly experienced in
                                                                                    digital marketing, and we strive to
                                                                                    provide the highest level of service
                                                                                    to our clients. We understand that
                                                                                    each client has specific goals and
                                                                                    objectives, and we customize our
                                                                                    services to meet those objectives.
                                                                                    Our services are designed to help
                                                                                    businesses reach their target
                                                                                    audience, build their brand, and
                                                                                    increase their sales.</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid testimonials-area" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                        <div className="wpb_wrapper">
                                                                            <h5>What <span>Our Client</span> Say's</h5>
                                                                            <p>Serving our clients with top-notch
                                                                                results is our major priority, htmlFor each
                                                                                expert at NavUday Solution Digital, our motive is
                                                                                to build a strong relationship with our
                                                                                client htmlFor equivalent growth and
                                                                                success.</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="white" style={{ background: "rgba(0,0,0,0)", border: "solid 0px rgba(0,0,0,0)", borderRadius: 0, padding: "0px 0px 0px 0px" }}>
                                                                                <div id="slider_115" className="owl-carousel sa_owl_theme autohide-arrows owl-loaded owl-drag" data-slider-id="slider_115" style={{ visibility: "visible" }}>




                                                                                    <div className="owl-stage-outer">
                                                                                        <div className="owl-stage" style={{ transform: "translate3d(-1775px, 0px, 0px)", transition: "0.2s", width: 2840 }}>
                                                                                            <div className="owl-item cloned" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>NavUday Solution
                                                                                                                Digital's
                                                                                                                website
                                                                                                                design
                                                                                                                services
                                                                                                                are
                                                                                                                top-notch.
                                                                                                                Their
                                                                                                                team is
                                                                                                                professional,
                                                                                                                talented
                                                                                                                and very
                                                                                                                responsive.
                                                                                                                They
                                                                                                                created
                                                                                                                a
                                                                                                                beautiful
                                                                                                                website
                                                                                                                that
                                                                                                                perfectly
                                                                                                                represents
                                                                                                                our
                                                                                                                brand
                                                                                                                and
                                                                                                                effectively
                                                                                                                communicates
                                                                                                                our
                                                                                                                message
                                                                                                                to our
                                                                                                                target
                                                                                                                audience.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Shubham
                                                                                                            Chabbra
                                                                                                            (Sketra)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>NavUday Solution
                                                                                                                Experts
                                                                                                                helped
                                                                                                                us take
                                                                                                                our home
                                                                                                                decor
                                                                                                                marketplace
                                                                                                                to the
                                                                                                                next
                                                                                                                level.
                                                                                                                Their
                                                                                                                team has
                                                                                                                been
                                                                                                                instrumental
                                                                                                                in
                                                                                                                improving
                                                                                                                our
                                                                                                                search
                                                                                                                engine
                                                                                                                rankings,
                                                                                                                increasing
                                                                                                                our
                                                                                                                organic
                                                                                                                traffic
                                                                                                                and
                                                                                                                driving
                                                                                                                more
                                                                                                                sales.
                                                                                                                Their
                                                                                                                professionalism,
                                                                                                                support
                                                                                                                and
                                                                                                                expertise
                                                                                                                have
                                                                                                                been a
                                                                                                                great
                                                                                                                asset to
                                                                                                                our
                                                                                                                business.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!"
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Manjula
                                                                                                            (Good Homes
                                                                                                            Company)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>They have
                                                                                                                been a
                                                                                                                game
                                                                                                                changer
                                                                                                                htmlFor our
                                                                                                                cleaning
                                                                                                                services
                                                                                                                business.
                                                                                                                Their
                                                                                                                team has
                                                                                                                helped
                                                                                                                us
                                                                                                                generate
                                                                                                                qualified
                                                                                                                leads
                                                                                                                through
                                                                                                                targeted
                                                                                                                campaigns.
                                                                                                                Their
                                                                                                                professionalism
                                                                                                                and
                                                                                                                expertise
                                                                                                                in lead
                                                                                                                generation
                                                                                                                have
                                                                                                                been
                                                                                                                invaluable
                                                                                                                to our
                                                                                                                business.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!"
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Alice
                                                                                                            Allen(Dirt2Tidy)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>We are
                                                                                                                extremely
                                                                                                                satisfied
                                                                                                                with the
                                                                                                                results
                                                                                                                we have
                                                                                                                seen
                                                                                                                from
                                                                                                                NavUday Solution
                                                                                                                Digital's
                                                                                                                SEO
                                                                                                                services.
                                                                                                                They
                                                                                                                have
                                                                                                                helped
                                                                                                                us
                                                                                                                increase
                                                                                                                our
                                                                                                                online
                                                                                                                visibility
                                                                                                                and
                                                                                                                drive
                                                                                                                more
                                                                                                                traffic
                                                                                                                to our
                                                                                                                website.
                                                                                                                Their
                                                                                                                team is
                                                                                                                easy to
                                                                                                                work
                                                                                                                with and
                                                                                                                always
                                                                                                                goes
                                                                                                                above
                                                                                                                and
                                                                                                                beyond
                                                                                                                to
                                                                                                                exceed
                                                                                                                our
                                                                                                                expectations
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Juka (Juka
                                                                                                            Organics)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>NavUday Solution
                                                                                                                Digital's
                                                                                                                website
                                                                                                                design
                                                                                                                services
                                                                                                                are
                                                                                                                top-notch.
                                                                                                                Their
                                                                                                                team is
                                                                                                                professional,
                                                                                                                talented
                                                                                                                and very
                                                                                                                responsive.
                                                                                                                They
                                                                                                                created
                                                                                                                a
                                                                                                                beautiful
                                                                                                                website
                                                                                                                that
                                                                                                                perfectly
                                                                                                                represents
                                                                                                                our
                                                                                                                brand
                                                                                                                and
                                                                                                                effectively
                                                                                                                communicates
                                                                                                                our
                                                                                                                message
                                                                                                                to our
                                                                                                                target
                                                                                                                audience.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Shubham
                                                                                                            Chabbra
                                                                                                            (Sketra)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item active" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>NavUday Solution
                                                                                                                Experts
                                                                                                                helped
                                                                                                                us take
                                                                                                                our home
                                                                                                                decor
                                                                                                                marketplace
                                                                                                                to the
                                                                                                                next
                                                                                                                level.
                                                                                                                Their
                                                                                                                team has
                                                                                                                been
                                                                                                                instrumental
                                                                                                                in
                                                                                                                improving
                                                                                                                our
                                                                                                                search
                                                                                                                engine
                                                                                                                rankings,
                                                                                                                increasing
                                                                                                                our
                                                                                                                organic
                                                                                                                traffic
                                                                                                                and
                                                                                                                driving
                                                                                                                more
                                                                                                                sales.
                                                                                                                Their
                                                                                                                professionalism,
                                                                                                                support
                                                                                                                and
                                                                                                                expertise
                                                                                                                have
                                                                                                                been a
                                                                                                                great
                                                                                                                asset to
                                                                                                                our
                                                                                                                business.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!"
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Manjula
                                                                                                            (Good Homes
                                                                                                            Company)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>They have
                                                                                                                been a
                                                                                                                game
                                                                                                                changer
                                                                                                                htmlFor our
                                                                                                                cleaning
                                                                                                                services
                                                                                                                business.
                                                                                                                Their
                                                                                                                team has
                                                                                                                helped
                                                                                                                us
                                                                                                                generate
                                                                                                                qualified
                                                                                                                leads
                                                                                                                through
                                                                                                                targeted
                                                                                                                campaigns.
                                                                                                                Their
                                                                                                                professionalism
                                                                                                                and
                                                                                                                expertise
                                                                                                                in lead
                                                                                                                generation
                                                                                                                have
                                                                                                                been
                                                                                                                invaluable
                                                                                                                to our
                                                                                                                business.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!"
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Alice
                                                                                                            Allen(Dirt2Tidy)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>We are
                                                                                                                extremely
                                                                                                                satisfied
                                                                                                                with the
                                                                                                                results
                                                                                                                we have
                                                                                                                seen
                                                                                                                from
                                                                                                                NavUday Solution
                                                                                                                Digital's
                                                                                                                SEO
                                                                                                                services.
                                                                                                                They
                                                                                                                have
                                                                                                                helped
                                                                                                                us
                                                                                                                increase
                                                                                                                our
                                                                                                                online
                                                                                                                visibility
                                                                                                                and
                                                                                                                drive
                                                                                                                more
                                                                                                                traffic
                                                                                                                to our
                                                                                                                website.
                                                                                                                Their
                                                                                                                team is
                                                                                                                easy to
                                                                                                                work
                                                                                                                with and
                                                                                                                always
                                                                                                                goes
                                                                                                                above
                                                                                                                and
                                                                                                                beyond
                                                                                                                to
                                                                                                                exceed
                                                                                                                our
                                                                                                                expectations
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Juka (Juka
                                                                                                            Organics)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="owl-nav"><button type="button" role="presentation" className="owl-prev">Previous</button><button type="button" role="presentation" className="owl-next">Next</button>
                                                                                    </div>
                                                                                    <div className="owl-dots disabled">
                                                                                    </div>
                                                                                    <div className="owl-thumbs" />
                                                                                </div>
                                                                            </div>
                                                                           

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                </div>{ /* .entry-content */ }

                            </article>{ /* #post-## */ }

                        </div>{ /* #main */ }
                    </section>{ /* #primary */ }

                </div>{ /* .row */ }
            </div>{ /* .container */ }
        </div>



</>
  )
}

export default Socialmedia
