import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Component/Navbar";
import Footer from "./Component/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contactus from "./Pages/Contactus";
import Seopage from "./Servies/Seopage";
import PPc from "./Servies/PPc";
import Googleads from "./Servies/Googleads";
import Brandbuild from "./Servies/Brandbuild";
import Socialmedia from "./Servies/Socialmedia";
import Webdesign from "./Servies/Webdesign";


function App() {
  return (
    <>
      <Router>

      
      <div className="topheader deskshow">
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                </div>
                <div className="col-lg-9">
                    <ul className="header-right">
                        <li><a href="tel:+919999999999">SALES INQUIRY</a></li>
                        <li><a href="tel:+919999999999"><i className="fa fa-phone" aria-hidden="true" /> +91 999999999
                            </a></li>
                        <li><a href="mailto:"> </a></li>
                        <li><a href="" className="carr skp"> Career</a>
                        </li>
                        <li><a href="" target="_blank" className="skp"><i className="fab fa-skype" /> Skype</a></li>
                        <li><a href="#" className="see pum-trigger" style={{ cursor: "pointer" }}><i className="fas fa-headset" />
                                Help Center</a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div id="page" className="site">
        <Navbar/>


        <Routes>

        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contactus/>} />
        <Route path="/seopage" element={<Seopage/>} />
        <Route path="/ppc" element={<PPc/>} />
        <Route path="/googleads" element={<Googleads/>} />
        <Route path="/brandbuild" element={<Brandbuild/>} />
        <Route path="/socialmedia" element={<Socialmedia/>} />
        <Route path="/webdesign" element={<Webdesign/>} />


         
        </Routes>


      


        
    </div>



       

        <Footer />
      </Router>
    </>
  );
}

export default App;
