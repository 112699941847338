import React from 'react'

function Webdesign() {
  return (
   <>

<style type="text/css" data-type="vc_shortcodes-custom-css">{`
        .vc_custom_1672420766299 {
            background-image: url(https://adsdad.com/wp-content/uploads/2022/12/ppcbg-new.jpg?id=171) !important;
        }

        .vc_custom_1676573849478 {
            border-bottom-width: 1px !important;
            background-color: #02355a !important;
            border-bottom-color: #00000033 !important;
        }

        .vc_custom_1676573902626 {
            background-color: #002e50 !important;
        }
    `}</style>
   
   <div id="content" className="site-content">
            <div className="container">
                <div className="row">

                    <section id="primary" className="content-area col-sm-12">
                        <div id="main" className="site-main" role="main">


                            <article id="post-303" className="post-303 page type-page status-publish hentry">

                                <div className="entry-content">
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-ppc vc_custom_1672420766299 vc_row-has-fill vc_row-no-padding" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-8">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html ppc-area-hd">
                                                                        <div className="wpb_wrapper">
                                                                            <h1>Leading Web Design & Development
                                                                                Agency</h1>
                                                                            <h2>GET A RESPONSIVE SEO FRIENDLY, EYE
                                                                                CATCHING, ENGAGING AND RESULT DRIVEN
                                                                                WEBSITE</h2>
                                                                            <p>Our website design approach focuses on
                                                                                achieving your business goals in order
                                                                                to increase return on investment htmlFor
                                                                                your business that bring customer from
                                                                                all over the world. We look forward to
                                                                                talking with our clients about their
                                                                                ideas and then working with our team to
                                                                                come up with solutions to make it
                                                                                happen!</p>
                                                                            <div className="ftp-top">
                                                                                <ul className="crtified">
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"/></noscript>
                                                                                    </li>
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"/></noscript>
                                                                                    </li>
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"/></noscript>
                                                                                    </li>
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"/></noscript>
                                                                                    </li>
                                                                                    <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"/></noscript>
                                                                                    </li>
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpcf7 js" id="wpcf7-f971-p303-o3" lang="en-US" dir="ltr">
                                                                        <div className="screen-reader-response">
                                                                            <p role="status" aria-live="polite" aria-atomic="true" />
                                                                            <ul />
                                                                        </div>
                                                                        <form action="/web-design-development-company-delhi/#wpcf7-f971-p303-o3" method="post" className="wpcf7-form init" aria-label="Contact form" noValidate data-status="init">
                                                                            <div style={{ display: "none" }}>
                                                                                <input type="hidden" name="_wpcf7" value="971" />
                                                                                <input type="hidden" name="_wpcf7_version" value="5.9.8" />
                                                                                <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                                                                <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f971-p303-o3" />
                                                                                <input type="hidden" name="_wpcf7_container_post" value="303" />
                                                                                <input type="hidden" name="_wpcf7_posted_data_hash"  />
                                                                            </div>
                                                                            <div className="ppcform">
                                                                                <h5>Improve Results
                                                                                </h5>
                                                                                <h6>with a better website today
                                                                                </h6>
                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="fld">
                                                                                            <p><span className="wpcf7-form-control-wrap" data-name="text-659"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" placeholder="Full Name"  type="text" name="text-659" fdprocessedid="jkga2" /></span><i className="fas fa-user" />
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="fld">
                                                                                            <p><span className="wpcf7-form-control-wrap" data-name="email-722"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control" aria-required="true" aria-invalid="false" placeholder="Email Address"  type="email" name="email-722" fdprocessedid="nmwp1t" /></span><i className="fas fa-envelope" />
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="fld">
                                                                                            <p><span className="wpcf7-form-control-wrap" data-name="tel-659"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control" aria-required="true" aria-invalid="false" placeholder="Phone No"  type="tel" name="tel-659" fdprocessedid="63kfd5" /></span><i className="fas fa-phone-alt" />
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="fld but">
                                                                                            <p><input className="wpcf7-form-control wpcf7-submit has-spinner btn btn-primary" type="submit" value="Get Your Free Proposal" fdprocessedid="7uozt8" /><span className="wpcf7-spinner" /><i className="fa fa-angle-right" aria-hidden="true" />
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div><input type="hidden" className="wpcf7-pum" />
                                                                            <div className="wpcf7-response-output" aria-hidden="true" />
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            { /* Widget Shortcode */ }
                                                                            <div id="custom_html-2" className="widget_text widget widget_custom_html widget-shortcode area-arbitrary ">
                                                                                <div className="textwidget custom-html-widget">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3">
                                                                                            <div className="box-area">
                                                                                                <p>We Have Over</p>
                                                                                                <h5>3+</h5>
                                                                                                <span>Years of
                                                                                                    Experience</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="box-area">
                                                                                                <p>Expertise In</p>
                                                                                                <h5>12+</h5>
                                                                                                <span>Technologies</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="box-area">
                                                                                                <p>We Have Done</p>
                                                                                                <h5>2K+</h5>
                                                                                                <span>Projects &
                                                                                                    Counting</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-3">
                                                                                            <div className="box-area">
                                                                                                <p>Clients In</p>
                                                                                                <h5>28+</h5>
                                                                                                <span>Countries</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>{ /* /Widget Shortcode */ }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-top-bottm" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Evaluate your brand with a website that
                                                                                speaks to your customers.</h4>
                                                                            <p>Get the best-trusted website design that
                                                                                will win the hearts of each customer
                                                                                with the help of best website designing
                                                                                company in Delhi. We don’t like to brag,
                                                                                but our experts in web design &
                                                                                development know what to present and how
                                                                                to present to each audience.</p>

                                                                        </div>
                                                                    </div>

                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  top-img">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img fetchpriority="high" decoding="async" width="938" height="630" src="https://adsdad.com/wp-content/uploads/2023/02/Web-Design-Services.jpg" className="vc_single_image-img attachment-full" alt="" title="Web-Design-Services" srcSet="https://adsdad.com/wp-content/uploads/2023/02/Web-Design-Services.jpg 938w, https://adsdad.com/wp-content/uploads/2023/02/Web-Design-Services-300x201.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/Web-Design-Services-768x516.jpg 768w" sizes="(max-width: 938px) 100vw, 938px" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-50" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  boxraea">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img decoding="async" width="907" height="520" data-src="https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux.jpg" className="vc_single_image-img attachment-full lazyloaded" alt="" title="web-ui-ux" data-srcset="https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux.jpg 907w, https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux-300x172.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux-768x440.jpg 768w" data-sizes="(max-width: 907px) 100vw, 907px" src="https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux.jpg" style={{ SmushPlaceholderWidth: 907, SmushPlaceholderAspectRatio: "907/520" }} sizes="(max-width: 907px) 100vw, 907px" srcSet="https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux.jpg 907w, https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux-300x172.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux-768x440.jpg 768w" /><noscript><img
                                                                                        decoding="async" width="907"
                                                                                        height="520"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux.jpg"
                                                                                        className="vc_single_image-img attachment-full"
                                                                                        alt="" title="web-ui-ux"
                                                                                        srcSet="https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux.jpg 907w, https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux-300x172.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/web-ui-ux-768x440.jpg 768w"
                                                                                        sizes="(max-width: 907px) 100vw, 907px" /></noscript>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Let’s experience high results-driven
                                                                                user interaction that will give your ROI
                                                                                a ‘BOOM.’</h4>
                                                                            <p>Let’s keep it short here, we will create
                                                                                your customizable website from scratch
                                                                                with the proper keyword mapping, giving
                                                                                your brand an edge over competitors. The
                                                                                classic idea is to add punchy CTA’s,
                                                                                allowing your customers to get in touch
                                                                                with you via any device.</p>

                                                                        </div>
                                                                    </div>

                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="text-green"><span>75%</span>
                                                                                <p>of users judge a company's
                                                                                    credibility based on its website's
                                                                                    design.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-50" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Our experts will deeply analyze your
                                                                                business htmlFor your audience’s
                                                                                expectations.</h4>
                                                                            <p>The first impression is the last
                                                                                expression of your audience, and our web
                                                                                design company in Delhi will demonstrate
                                                                                your website and build trust within the
                                                                                industry.</p>
                                                                            <div className="btn-rt">
                                                                                <ul>
                                                                                    <li><a href="#">WordPress </a></li>
                                                                                    <li><a href="#">E-commerce</a></li>
                                                                                    <li><a href="#">Custom Web
                                                                                            Development</a></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  top-img boxraea">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img decoding="async" width="1016" height="667" data-src="https://adsdad.com/wp-content/uploads/2023/02/web-designing-company.jpg" className="vc_single_image-img attachment-full lazyload" alt="" title="web-designing-company" data-srcset="https://adsdad.com/wp-content/uploads/2023/02/web-designing-company.jpg 1016w, https://adsdad.com/wp-content/uploads/2023/02/web-designing-company-300x197.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/web-designing-company-768x504.jpg 768w" data-sizes="(max-width: 1016px) 100vw, 1016px" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" style={{ SmushPlaceholderWidth: 1016, SmushPlaceholderAspectRatio: "1016/667" }} /><noscript><img
                                                                                        decoding="async" width="1016"
                                                                                        height="667"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/web-designing-company.jpg"
                                                                                        className="vc_single_image-img attachment-full"
                                                                                        alt=""
                                                                                        title="web-designing-company"
                                                                                        srcSet="https://adsdad.com/wp-content/uploads/2023/02/web-designing-company.jpg 1016w, https://adsdad.com/wp-content/uploads/2023/02/web-designing-company-300x197.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/web-designing-company-768x504.jpg 768w"
                                                                                        sizes="(max-width: 1016px) 100vw, 1016px" /></noscript>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid media-managment vc_custom_1676573849478 vc_row-has-fill" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  title-faq wht">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>Website features that you will love</h4>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid mm vc_custom_1676573902626 vc_row-has-fill" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="right-bottom wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/responsive-websites.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 80, SmushPlaceholderAspectRatio: "80/74" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/responsive-websites.png" /></noscript><br />
                                                                                <span className="tit"><span style={{ fontWeight: "400" }}>Best
                                                                                        Suited htmlFor Every
                                                                                        Business.</span></span>
                                                                            </p>
                                                                            <p><span style={{ fontWeight: "400" }}>Website
                                                                                    plays a major role in building
                                                                                    customer trust and authenticity and
                                                                                    is suited htmlFor all kinds of
                                                                                    business.</span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="right-bottom wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/safe-and-secure.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 61, SmushPlaceholderAspectRatio: "61/74" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/safe-and-secure.png" /></noscript>
                                                                            </p>
                                                                            <p><span className="tit"><span style={{ fontWeight: "400" }}>Protected
                                                                                        and Secure </span></span>
                                                                            </p>
                                                                            <p><span style={{ fontWeight: "400" }}>A perfect
                                                                                    website design & management
                                                                                    ensure protection and security htmlFor
                                                                                    its visitors searching htmlFor your
                                                                                    brand and services. </span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="onlybottom wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/website-loads-super-fast.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 81, SmushPlaceholderAspectRatio: "81/77" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/website-loads-super-fast.png" /></noscript>
                                                                            </p>
                                                                            <p><span className="tit"><span style={{ fontWeight: "400" }}>Super
                                                                                        Fast Loading </span><br />
                                                                                </span><br />
                                                                                <span style={{ fontWeight: "400" }}>A
                                                                                    perfectly loaded website symbolizes
                                                                                    righteousness and a safe place htmlFor
                                                                                    the customer.</span>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="left-bottom wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/website-visitors-tracking.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 81, SmushPlaceholderAspectRatio: "81/74" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/website-visitors-tracking.png" /></noscript><br />
                                                                                <span className="tit"><span style={{ fontWeight: "400" }}>Keep
                                                                                        Track of Your
                                                                                        Visitors</span></span>
                                                                            </p>
                                                                            <p><span style={{ fontWeight: "400" }}>With the
                                                                                    help of Tools like Google Analytics
                                                                                    and Semrush, get to know each
                                                                                    visitor and lead.  </span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="onlyright wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/seo-optimized-webdesign.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 75, SmushPlaceholderAspectRatio: "75/74" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/seo-optimized-webdesign.png" /></noscript><br />
                                                                                <span className="tit"><span style={{ fontWeight: "400" }}>SEO
                                                                                        Optimized</span><br />
                                                                                </span>
                                                                            </p>
                                                                            <p><span style={{ fontWeight: "400" }}>An
                                                                                    SEO-optimized website works as a
                                                                                    helping hand to reach organic
                                                                                    customers.</span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="onlyright wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/easy-to-manage.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 74, SmushPlaceholderAspectRatio: "74/74" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/easy-to-manage.png" /></noscript><br />
                                                                                <span className="tit"><span style={{ fontWeight: "400" }}>Easy
                                                                                        to Operate</span></span>
                                                                            </p>
                                                                            <p><span style={{ fontWeight: "400" }}>A website
                                                                                    is a single platform where you can
                                                                                    experiment and showcase all your
                                                                                    products & services on the same
                                                                                    page. </span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="on-padd wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/ongoing-support.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 81, SmushPlaceholderAspectRatio: "81/77" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/ongoing-support.png" /></noscript><br />
                                                                                <span className="tit"><span style={{ fontWeight: "400" }}>24×7
                                                                                        Customer Support </span></span>
                                                                            </p>
                                                                            <p><span style={{ fontWeight: "400" }}>An
                                                                                    inquiry section and live chat work
                                                                                    as a cherry on the cake htmlFor the
                                                                                    visitors.</span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="botmleft wpb_column vc_column_container vc_col-sm-3">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  socilbx">
                                                                        <div className="wpb_wrapper">
                                                                            <p><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/full-ownership-of-everything.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 69, SmushPlaceholderAspectRatio: "69/76" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/02/full-ownership-of-everything.png" /></noscript><br />
                                                                                <span className="tit"><span style={{ fontWeight: "400" }}>For
                                                                                        You, With You</span></span>
                                                                            </p>
                                                                            <p><span style={{ fontWeight: "400" }}>A website
                                                                                    acts as a trustworthy license htmlFor
                                                                                    the visitor, and any kind of
                                                                                    business is a perfect act of
                                                                                    attraction. </span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-100" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  addverting">
                                                                        <div className="wpb_wrapper">
                                                                            <h4>We Build Website On</h4>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_single_image wpb_content_element vc_align_left">

                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img loading="lazy" decoding="async" width="1368" height="153" data-src="https://adsdad.com/wp-content/uploads/2024/02/dev.png" className="vc_single_image-img attachment-full lazyload" alt="" title="dev" data-srcset="https://adsdad.com/wp-content/uploads/2024/02/dev.png 1368w, https://adsdad.com/wp-content/uploads/2024/02/dev-300x34.png 300w, https://adsdad.com/wp-content/uploads/2024/02/dev-1024x115.png 1024w, https://adsdad.com/wp-content/uploads/2024/02/dev-768x86.png 768w" data-sizes="(max-width: 1368px) 100vw, 1368px" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" style={{ SmushPlaceholderWidth: 1368, SmushPlaceholderAspectRatio: "1368/153" }} /><noscript><img
                                                                        loading="lazy" decoding="async" width="1368"
                                                                        height="153"
                                                                        src="https://adsdad.com/wp-content/uploads/2024/02/dev.png"
                                                                        className="vc_single_image-img attachment-full"
                                                                        alt="" title="dev"
                                                                        srcSet="https://adsdad.com/wp-content/uploads/2024/02/dev.png 1368w, https://adsdad.com/wp-content/uploads/2024/02/dev-300x34.png 300w, https://adsdad.com/wp-content/uploads/2024/02/dev-1024x115.png 1024w, https://adsdad.com/wp-content/uploads/2024/02/dev-768x86.png 768w"
                                                                        sizes="(max-width: 1368px) 100vw, 1368px" /></noscript>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-50 bgcolor-blue" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                                                        <div className="wpb_wrapper">
                                                                            <h3 className="whitehead">Know You’re Supported
                                                                            </h3>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="boxraea">
                                                                                <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/05/2660399.jpg" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 1920, SmushPlaceholderAspectRatio: "1920/1200" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/05/2660399.jpg" /></noscript>
                                                                                <h5>Create your Brand Image</h5>
                                                                                <p>Our experts in website designing are
                                                                                    to give a feel look to your brand
                                                                                    and a positive image among the
                                                                                    audience.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="boxraea">
                                                                                <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/05/FDmGCvPX0AQWInA.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 1680, SmushPlaceholderAspectRatio: "1680/960" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/05/FDmGCvPX0AQWInA.png" /></noscript>
                                                                                <h5>Website that Runs Faster and Better
                                                                                    Wins the Heart</h5>
                                                                                <p>Our web development company in delhi
                                                                                    will decrease the load of your
                                                                                    website and make it smooth and easy
                                                                                    to run in the industry.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="boxraea">
                                                                                <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/05/microsoft-dyanmics-365-customer-service.jpg" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 1920, SmushPlaceholderAspectRatio: "1920/1100" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/05/microsoft-dyanmics-365-customer-service.jpg" /></noscript>
                                                                                <h5>24x7 Customer Support</h5>
                                                                                <p>Our website designer in delhi are a
                                                                                    man of our work; once we commit,
                                                                                    there is no going back. We are here
                                                                                    htmlFor you in your complex and happy
                                                                                    times.</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid bg-color-grey" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="addverting">
                                                                                <h4>Remarkable results htmlFor businesses
                                                                                </h4>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_left">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img loading="lazy" decoding="async" width="1625" height="615" data-src="https://adsdad.com/wp-content/uploads/2023/12/most-popular-platforms.gif" className="vc_single_image-img attachment-full lazyload" alt="" title="most-popular-platforms" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" style={{ SmushPlaceholderWidth: 1625, SmushPlaceholderAspectRatio: "1625/615" }} /><noscript><img
                                                                                        loading="lazy" decoding="async"
                                                                                        width="1625" height="615"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/12/most-popular-platforms.gif"
                                                                                        className="vc_single_image-img attachment-full"
                                                                                        alt=""
                                                                                        title="most-popular-platforms" /></noscript>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid faq-bottom-padding" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html title-faq">
                                                                        <div className="wpb_wrapper">
                                                                            <h3>Questions to Ask When Hiring a Web
                                                                                Design & Development Agency</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>Why every business needs a website?</h4>
                                                                            <i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>Every
                                                                                    business needs a website htmlFor a
                                                                                    variety of reasons. Firstly, a
                                                                                    website provides a platform to
                                                                                    showcase products and services to
                                                                                    potential customers. Additionally, a
                                                                                    website can increase brand awareness
                                                                                    and establish credibility.
                                                                                    Furthermore, having a website allows
                                                                                    businesses to build relationships
                                                                                    with customers and provide an
                                                                                    opportunity htmlFor customers to give
                                                                                    feedback and make purchases. Lastly,
                                                                                    a website can increase visibility
                                                                                    and be an effective marketing tool
                                                                                    htmlFor reaching a larger
                                                                                    audience.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>How much does a new website cost?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>The cost
                                                                                    of a new website can depend on
                                                                                    various factors, including the size
                                                                                    and complexity of the website, the
                                                                                    design and development services
                                                                                    required, and the hosting and
                                                                                    maintenance fees. Prices htmlFor a new
                                                                                    website can range from a few hundred
                                                                                    dollars htmlFor a basic site to tens of
                                                                                    thousands of dollars htmlFor a more
                                                                                    complex site.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>How long does it take to design a
                                                                                website?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>The
                                                                                    amount of time it takes to design a
                                                                                    website will depend on the scope and
                                                                                    complexity of the project. A simple
                                                                                    website with a few pages can take
                                                                                    anywhere from one to two weeks to
                                                                                    design, while a more complex website
                                                                                    with multiple features, such as an
                                                                                    e-commerce store, can take up to
                                                                                    several months.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>What kind of website design services do
                                                                                you offer?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>We offer
                                                                                    various website design services,
                                                                                    including website design, website
                                                                                    development, website hosting, search
                                                                                    engine optimization, content
                                                                                    marketing, and email marketing. We
                                                                                    also provide custom solutions htmlFor
                                                                                    unique business needs.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>How does the design & development
                                                                                process work?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>Design
                                                                                    and development processes typically
                                                                                    involve several steps. The first
                                                                                    step is to define the project scope
                                                                                    and requirements. This step consists
                                                                                    in gathering the necessary
                                                                                    information and creating a strategy
                                                                                    for the project. The next step is to
                                                                                    create a design concept, which
                                                                                    includes designing the look and feel
                                                                                    of the website, developing the user
                                                                                    experience, and making an
                                                                                    information architecture. After the
                                                                                    design concept is approved, the
                                                                                    development process begins, which
                                                                                    includes coding the website,
                                                                                    creating databases, testing, and
                                                                                    deploying the website. Once the
                                                                                    website is deployed, it is typically
                                                                                    monitored and maintained htmlFor any
                                                                                    changes or updates.</span></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                                                        <div className="vc_toggle_title">
                                                                            <h4>Why Choose NavUday Solution Digital Web Design
                                                                                & Development Services?</h4><i className="vc_toggle_icon" />
                                                                        </div>
                                                                        <div className="vc_toggle_content">
                                                                            <p><span style={{ fontWeight: "400" }}>NavUday Solution
                                                                                    Digital Web Design & Development
                                                                                    Services offers many services to
                                                                                    help you create a compelling and
                                                                                    successful online presence. Our
                                                                                    experienced and knowledgeable
                                                                                    professionals are committed to
                                                                                    delivering the best results.  
                                                                                    We provide complete web design and
                                                                                    development services, including
                                                                                    website design, content management,
                                                                                    e-commerce solutions, search engine
                                                                                    optimization, web hosting, domain
                                                                                    name registration, and
                                                                                    more. </span></p>
                                                                            <p><span style={{ fontWeight: "400" }}>We use
                                                                                    the latest technologies and
                                                                                    techniques to optimize your website
                                                                                    htmlFor the best performance and user
                                                                                    experience. Our team of experts is
                                                                                    committed to providing the highest
                                                                                    quality customer service. It will go
                                                                                    the extra mile to ensure your
                                                                                    website is up and running quickly
                                                                                    and efficiently. We are dedicated to
                                                                                    helping you create an effective
                                                                                    online presence that will help you
                                                                                    reach your business goals.</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid testimonials-area" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                        <div className="wpb_wrapper">
                                                                            <h5>What <span>Our Client</span> Say's</h5>
                                                                            <p>Serving our clients with top-notch
                                                                                results is our major priority, htmlFor each
                                                                                expert at NavUday Solution Digital, our motive is
                                                                                to build a strong relationship with our
                                                                                client htmlFor equivalent growth and
                                                                                success.</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="white" style={{ background: "rgba(0,0,0,0)", border: "solid 0px rgba(0,0,0,0)", borderRadius: 0, padding: "0px 0px 0px 0px" }}>
                                                                                <div id="slider_115" className="owl-carousel sa_owl_theme autohide-arrows owl-loaded owl-drag" data-slider-id="slider_115" style={{ visibility: "visible" }}>




                                                                                    <div className="owl-stage-outer">
                                                                                        <div className="owl-stage" style={{ transform: "translate3d(-1775px, 0px, 0px)", transition: "0.2s", width: 2840 }}>
                                                                                            <div className="owl-item cloned" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>NavUday Solution
                                                                                                                Digital's
                                                                                                                website
                                                                                                                design
                                                                                                                services
                                                                                                                are
                                                                                                                top-notch.
                                                                                                                Their
                                                                                                                team is
                                                                                                                professional,
                                                                                                                talented
                                                                                                                and very
                                                                                                                responsive.
                                                                                                                They
                                                                                                                created
                                                                                                                a
                                                                                                                beautiful
                                                                                                                website
                                                                                                                that
                                                                                                                perfectly
                                                                                                                represents
                                                                                                                our
                                                                                                                brand
                                                                                                                and
                                                                                                                effectively
                                                                                                                communicates
                                                                                                                our
                                                                                                                message
                                                                                                                to our
                                                                                                                target
                                                                                                                audience.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Shubham
                                                                                                            Chabbra
                                                                                                            (Sketra)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>NavUday Solution
                                                                                                                Experts
                                                                                                                helped
                                                                                                                us take
                                                                                                                our home
                                                                                                                decor
                                                                                                                marketplace
                                                                                                                to the
                                                                                                                next
                                                                                                                level.
                                                                                                                Their
                                                                                                                team has
                                                                                                                been
                                                                                                                instrumental
                                                                                                                in
                                                                                                                improving
                                                                                                                our
                                                                                                                search
                                                                                                                engine
                                                                                                                rankings,
                                                                                                                increasing
                                                                                                                our
                                                                                                                organic
                                                                                                                traffic
                                                                                                                and
                                                                                                                driving
                                                                                                                more
                                                                                                                sales.
                                                                                                                Their
                                                                                                                professionalism,
                                                                                                                support
                                                                                                                and
                                                                                                                expertise
                                                                                                                have
                                                                                                                been a
                                                                                                                great
                                                                                                                asset to
                                                                                                                our
                                                                                                                business.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!"
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Manjula
                                                                                                            (Good Homes
                                                                                                            Company)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>They have
                                                                                                                been a
                                                                                                                game
                                                                                                                changer
                                                                                                                htmlFor our
                                                                                                                cleaning
                                                                                                                services
                                                                                                                business.
                                                                                                                Their
                                                                                                                team has
                                                                                                                helped
                                                                                                                us
                                                                                                                generate
                                                                                                                qualified
                                                                                                                leads
                                                                                                                through
                                                                                                                targeted
                                                                                                                campaigns.
                                                                                                                Their
                                                                                                                professionalism
                                                                                                                and
                                                                                                                expertise
                                                                                                                in lead
                                                                                                                generation
                                                                                                                have
                                                                                                                been
                                                                                                                invaluable
                                                                                                                to our
                                                                                                                business.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!"
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Alice
                                                                                                            Allen(Dirt2Tidy)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>We are
                                                                                                                extremely
                                                                                                                satisfied
                                                                                                                with the
                                                                                                                results
                                                                                                                we have
                                                                                                                seen
                                                                                                                from
                                                                                                                NavUday Solution
                                                                                                                Digital's
                                                                                                                SEO
                                                                                                                services.
                                                                                                                They
                                                                                                                have
                                                                                                                helped
                                                                                                                us
                                                                                                                increase
                                                                                                                our
                                                                                                                online
                                                                                                                visibility
                                                                                                                and
                                                                                                                drive
                                                                                                                more
                                                                                                                traffic
                                                                                                                to our
                                                                                                                website.
                                                                                                                Their
                                                                                                                team is
                                                                                                                easy to
                                                                                                                work
                                                                                                                with and
                                                                                                                always
                                                                                                                goes
                                                                                                                above
                                                                                                                and
                                                                                                                beyond
                                                                                                                to
                                                                                                                exceed
                                                                                                                our
                                                                                                                expectations
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Juka (Juka
                                                                                                            Organics)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>NavUday Solution
                                                                                                                Digital's
                                                                                                                website
                                                                                                                design
                                                                                                                services
                                                                                                                are
                                                                                                                top-notch.
                                                                                                                Their
                                                                                                                team is
                                                                                                                professional,
                                                                                                                talented
                                                                                                                and very
                                                                                                                responsive.
                                                                                                                They
                                                                                                                created
                                                                                                                a
                                                                                                                beautiful
                                                                                                                website
                                                                                                                that
                                                                                                                perfectly
                                                                                                                represents
                                                                                                                our
                                                                                                                brand
                                                                                                                and
                                                                                                                effectively
                                                                                                                communicates
                                                                                                                our
                                                                                                                message
                                                                                                                to our
                                                                                                                target
                                                                                                                audience.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Shubham
                                                                                                            Chabbra
                                                                                                            (Sketra)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item active" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>NavUday Solution
                                                                                                                Experts
                                                                                                                helped
                                                                                                                us take
                                                                                                                our home
                                                                                                                decor
                                                                                                                marketplace
                                                                                                                to the
                                                                                                                next
                                                                                                                level.
                                                                                                                Their
                                                                                                                team has
                                                                                                                been
                                                                                                                instrumental
                                                                                                                in
                                                                                                                improving
                                                                                                                our
                                                                                                                search
                                                                                                                engine
                                                                                                                rankings,
                                                                                                                increasing
                                                                                                                our
                                                                                                                organic
                                                                                                                traffic
                                                                                                                and
                                                                                                                driving
                                                                                                                more
                                                                                                                sales.
                                                                                                                Their
                                                                                                                professionalism,
                                                                                                                support
                                                                                                                and
                                                                                                                expertise
                                                                                                                have
                                                                                                                been a
                                                                                                                great
                                                                                                                asset to
                                                                                                                our
                                                                                                                business.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!"
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Manjula
                                                                                                            (Good Homes
                                                                                                            Company)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>They have
                                                                                                                been a
                                                                                                                game
                                                                                                                changer
                                                                                                                htmlFor our
                                                                                                                cleaning
                                                                                                                services
                                                                                                                business.
                                                                                                                Their
                                                                                                                team has
                                                                                                                helped
                                                                                                                us
                                                                                                                generate
                                                                                                                qualified
                                                                                                                leads
                                                                                                                through
                                                                                                                targeted
                                                                                                                campaigns.
                                                                                                                Their
                                                                                                                professionalism
                                                                                                                and
                                                                                                                expertise
                                                                                                                in lead
                                                                                                                generation
                                                                                                                have
                                                                                                                been
                                                                                                                invaluable
                                                                                                                to our
                                                                                                                business.
                                                                                                                We
                                                                                                                highly
                                                                                                                recommend
                                                                                                                them!"
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Alice
                                                                                                            Allen(Dirt2Tidy)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: 355 }}>
                                                                                                <div id="slider_115_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                    <div className="review-bx">
                                                                                                        <div className="qutes-tst">
                                                                                                             </div>
                                                                                                        <div className="tstpara">
                                                                                                            <p>We are
                                                                                                                extremely
                                                                                                                satisfied
                                                                                                                with the
                                                                                                                results
                                                                                                                we have
                                                                                                                seen
                                                                                                                from
                                                                                                                NavUday Solution
                                                                                                                Digital's
                                                                                                                SEO
                                                                                                                services.
                                                                                                                They
                                                                                                                have
                                                                                                                helped
                                                                                                                us
                                                                                                                increase
                                                                                                                our
                                                                                                                online
                                                                                                                visibility
                                                                                                                and
                                                                                                                drive
                                                                                                                more
                                                                                                                traffic
                                                                                                                to our
                                                                                                                website.
                                                                                                                Their
                                                                                                                team is
                                                                                                                easy to
                                                                                                                work
                                                                                                                with and
                                                                                                                always
                                                                                                                goes
                                                                                                                above
                                                                                                                and
                                                                                                                beyond
                                                                                                                to
                                                                                                                exceed
                                                                                                                our
                                                                                                                expectations
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="strr">
                                                                                                            Juka (Juka
                                                                                                            Organics)<i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star" />
                                                                                                            <i className="fas fa-star-half-alt" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="owl-nav"><button type="button" role="presentation" className="owl-prev">Previous</button><button type="button" role="presentation" className="owl-next">Next</button>
                                                                                    </div>
                                                                                    <div className="owl-dots disabled">
                                                                                    </div>
                                                                                    <div className="owl-thumbs" />
                                                                                </div>
                                                                            </div>
                                                                          

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                </div>{ /* .entry-content */ }

                            </article>{ /* #post-## */ }

                        </div>{ /* #main */ }
                    </section>{ /* #primary */ }

                </div>{ /* .row */ }
            </div>{ /* .container */ }
        </div>
   
   
   
   
   
   
   </>
  )
}

export default Webdesign
