import React from "react";

function Brandbuild() {
  return (
    <>



<style type="text/css" data-type="vc_shortcodes-custom-css">{`
        .vc_custom_1672420766299 {
            background-image: url(https://adsdad.com/wp-content/uploads/2022/12/ppcbg-new.jpg?id=171) !important;
        }
    `}</style>


      <div id="content" className="site-content">
        <div className="container">
          <div className="row">
            <section id="primary" className="content-area col-sm-12">
              <div id="main" className="site-main" role="main">
                <article
                  id="post-301"
                  className="post-301 page type-page status-publish hentry"
                >
                  <div className="entry-content">
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-ppc vc_custom_1672420766299 vc_row-has-fill vc_row-no-padding"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-8">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html ppc-area-hd">
                                      <div className="wpb_wrapper">
                                        <h1>Leading Branding Agency</h1>
                                        <h2>
                                          BUILD A POWERFUL BRAND IMAGE WITH OUR
                                          STRATEGIC BRAND BUILDING SERVICES
                                        </h2>
                                        <p>
                                          We know how important it’s htmlFor
                                          your business to possess an identity
                                          that’s unique and consistent, so we
                                          work with you each step of the way.
                                          Our branding services are designed to
                                          assist you in building a powerful
                                          foundation for your company that will
                                          grow as big as you dream!
                                        </p>
                                        <div className="ftp-top">
                                          <ul className="crtified">
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"
                                                />
                                              </noscript>{" "}
                                            </li>
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"
                                                />
                                              </noscript>{" "}
                                            </li>
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"
                                                />
                                              </noscript>{" "}
                                            </li>
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"
                                                />
                                              </noscript>{" "}
                                            </li>
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"
                                                />
                                              </noscript>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div
                                      className="wpcf7 js"
                                      id="wpcf7-f985-p301-o3"
                                      lang="en-US"
                                      dir="ltr"
                                    >
                                      <div className="screen-reader-response">
                                        <p
                                          role="status"
                                          aria-live="polite"
                                          aria-atomic="true"
                                        />{" "}
                                        <ul />
                                      </div>
                                      <form
                                        action="/branding-agency-delhi/#wpcf7-f985-p301-o3"
                                        method="post"
                                        className="wpcf7-form init"
                                        aria-label="Contact form"
                                        noValidate
                                        data-status="init"
                                      >
                                        <div style={{ display: "none" }}>
                                          <input
                                            type="hidden"
                                            name="_wpcf7"
                                            value="985"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_version"
                                            value="5.9.8"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_locale"
                                            value="en_US"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_unit_tag"
                                            value="wpcf7-f985-p301-o3"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_container_post"
                                            value="301"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_posted_data_hash"
                                            
                                          />
                                        </div>
                                        <div className="ppcform">
                                          <h5>Build Brand</h5>
                                          <h6>with NavUday Solution Digital today</h6>
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <div className="fld">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="text-659"
                                                  >
                                                    <input
                                                      size={40}
                                                      maxLength={400}
                                                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Full Name"
                                                      
                                                      type="text"
                                                      name="text-659"
                                                      fdprocessedid="xziaq"
                                                    />
                                                  </span>
                                                  <i className="fas fa-user" />
                                                </p>
                                              </div>
                                              <div className="fld">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="email-722"
                                                  >
                                                    <input
                                                      size={40}
                                                      maxLength={400}
                                                      className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Email Address"
                                                      
                                                      type="email"
                                                      name="email-722"
                                                      fdprocessedid="1ys6jj"
                                                    />
                                                  </span>
                                                  <i className="fas fa-envelope" />
                                                </p>
                                              </div>
                                              <div className="fld">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="tel-659"
                                                  >
                                                    <input
                                                      size={40}
                                                      maxLength={400}
                                                      className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Phone No"
                                                      
                                                      type="tel"
                                                      name="tel-659"
                                                      fdprocessedid="enwwhs"
                                                    />
                                                  </span>
                                                  <i className="fas fa-phone-alt" />
                                                </p>
                                              </div>
                                              <div className="fld but">
                                                <p>
                                                  <input
                                                    className="wpcf7-form-control wpcf7-submit has-spinner btn btn-primary"
                                                    type="submit"
                                                    value="Get Your Free Proposal"
                                                    fdprocessedid="4yjun"
                                                  />
                                                  <span className="wpcf7-spinner" />
                                                  <i
                                                    className="fa fa-angle-right"
                                                    aria-hidden="true"
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <input
                                          type="hidden"
                                          className="wpcf7-pum"
                                        />
                                        <div
                                          className="wpcf7-response-output"
                                          aria-hidden="true"
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        {/* Widget Shortcode */}
                                        <div
                                          id="custom_html-2"
                                          className="widget_text widget widget_custom_html widget-shortcode area-arbitrary "
                                        >
                                          <div className="textwidget custom-html-widget">
                                            <div className="row">
                                              <div className="col-lg-3">
                                                <div className="box-area">
                                                  <p>We Have Over</p>
                                                  <h5>3+</h5>
                                                  <span>
                                                    Years of Experience
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-lg-3">
                                                <div className="box-area">
                                                  <p>Expertise In</p>
                                                  <h5>12+</h5>
                                                  <span>Technologies</span>
                                                </div>
                                              </div>
                                              <div className="col-lg-3">
                                                <div className="box-area">
                                                  <p>We Have Done</p>
                                                  <h5>2K+</h5>
                                                  <span>
                                                    Projects & Counting
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-lg-3">
                                                <div className="box-area">
                                                  <p>Clients In</p>
                                                  <h5>28+</h5>
                                                  <span>Countries</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* /Widget Shortcode */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-top-bottm"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Branding will give a boost to your
                                          business.
                                        </h4>
                                        <p>
                                          <span data-preserver-spaces="true">
                                            Branding is a process that
                                            encompasses your brand and helps you
                                            stand out from all competitors. 
                                          </span>
                                        </p>
                                        <p>
                                          <span data-preserver-spaces="true">
                                            • Increase Visual Identity
                                          </span>
                                        </p>
                                        <p>
                                          <span data-preserver-spaces="true">
                                            • Advertising and Communication 
                                          </span>
                                        </p>
                                        <p>• Public Realtions</p>
                                        <p>
                                          <span data-preserver-spaces="true">
                                            • Designing 
                                          </span>
                                        </p>
                                        <p>
                                          <span data-preserver-spaces="true">
                                            • Experience 
                                          </span>
                                        </p>
                                        <p>
                                          <span data-preserver-spaces="true">
                                            • Sponsoring and Partenrship
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp top-img wpb_start_animation animated">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            fetchpriority="high"
                                            decoding="async"
                                            width="1920"
                                            height="1225"
                                            src="https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding.jpg"
                                            className="vc_single_image-img attachment-full"
                                            alt=""
                                            title="Brand Branding Marketing Commercial Name Concept"
                                            srcSet="https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding.jpg 1920w, https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding-300x191.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding-1024x653.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding-768x490.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding-1536x980.jpg 1536w"
                                            sizes="(max-width: 1920px) 100vw, 1920px"
                                          />
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-50"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  boxraea">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            decoding="async"
                                            width="960"
                                            height="540"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720.jpg"
                                            className="vc_single_image-img attachment-full lazyloaded"
                                            alt=""
                                            title="light-bulb-3104355_960_720"
                                            data-srcset="https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720.jpg 960w, https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720-300x169.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720-768x432.jpg 768w"
                                            data-sizes="(max-width: 960px) 100vw, 960px"
                                            src="https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720.jpg"
                                            style={{
                                              SmushPlaceholderWidth: 960,
                                              SmushPlaceholderAspectRatio:
                                                "960/540",
                                            }}
                                            sizes="(max-width: 960px) 100vw, 960px"
                                            srcSet="https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720.jpg 960w, https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720-300x169.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720-768x432.jpg 768w"
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              width="960"
                                              height="540"
                                              src="https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720.jpg"
                                              class="vc_single_image-img attachment-full"
                                              alt=""
                                              title="light-bulb-3104355_960_720"
                                              srcset="https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720.jpg 960w, https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720-300x169.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/light-bulb-3104355_960_720-768x432.jpg 768w"
                                              sizes="(max-width: 960px) 100vw, 960px"
                                            />
                                          </noscript>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Enhance your brand image with creative
                                          and intellectual graphics.
                                        </h4>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            NavUday Solution graphic designing won’t copy
                                            the trend, and we create the trend
                                            in the visual world where we share
                                            and create your brand value in front
                                            of the world.{" "}
                                          </span>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="text-green">
                                          <span>76%</span>{" "}
                                          <p>
                                            of graphic designing made htmlFor
                                            both brand representation and the
                                            audience's productive output.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-50"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          The first and only golden rule about
                                          branding is this: it has to be
                                          authentic.
                                        </h4>
                                        <p>
                                          Your brand represents your promise to
                                          your customers … and yourself. The
                                          very first reason branding is
                                          important is that it very succinctly
                                          makes a promise to your customers. A
                                          good corporate will always tell each
                                          job seeker what they should expect—and
                                          hold you to—when they work with you.
                                          And a great ancillary benefit of that
                                          promise is that it keeps you honest
                                          with employees.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  top-img boxraea">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            decoding="async"
                                            width="2000"
                                            height="1333"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg"
                                            className="vc_single_image-img attachment-full lazyloaded"
                                            alt=""
                                            title="Graphic designer at work. Color samples."
                                            data-srcset="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg 2000w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-768x512.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1536x1024.jpg 1536w"
                                            data-sizes="(max-width: 2000px) 100vw, 2000px"
                                            src="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg"
                                            style={{
                                              SmushPlaceholderWidth: 2000,
                                              SmushPlaceholderAspectRatio:
                                                "2000/1333",
                                            }}
                                            sizes="(max-width: 2000px) 100vw, 2000px"
                                            srcSet="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg 2000w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-768x512.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1536x1024.jpg 1536w"
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              width="2000"
                                              height="1333"
                                              src="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg"
                                              class="vc_single_image-img attachment-full"
                                              alt=""
                                              title="Graphic designer at work. Color samples."
                                              srcset="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg 2000w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-768x512.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1536x1024.jpg 1536w"
                                              sizes="(max-width: 2000px) 100vw, 2000px"
                                            />
                                          </noscript>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-50"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh padding-50">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  boxraea">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            decoding="async"
                                            width="2000"
                                            height="1333"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg"
                                            className="vc_single_image-img attachment-full lazyloaded"
                                            alt=""
                                            title="Graphic designer at work. Color samples."
                                            data-srcset="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg 2000w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-768x512.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1536x1024.jpg 1536w"
                                            data-sizes="(max-width: 2000px) 100vw, 2000px"
                                            src="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg"
                                            style={{
                                              SmushPlaceholderWidth: 2000,
                                              SmushPlaceholderAspectRatio:
                                                "2000/1333",
                                            }}
                                            sizes="(max-width: 2000px) 100vw, 2000px"
                                            srcSet="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg 2000w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-768x512.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1536x1024.jpg 1536w"
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              width="2000"
                                              height="1333"
                                              src="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg"
                                              class="vc_single_image-img attachment-full"
                                              alt=""
                                              title="Graphic designer at work. Color samples."
                                              srcset="https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69.jpg 2000w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-768x512.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/cda864057329f26c2afbb2a074e08b69-1536x1024.jpg 1536w"
                                              sizes="(max-width: 2000px) 100vw, 2000px"
                                            />
                                          </noscript>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Creative problem-solving approach
                                          around human centered design.
                                        </h4>
                                        <p>
                                          Creative designing is building empathy
                                          with users. You can find a more
                                          impactful solution by understanding
                                          the person affected by a problem. On
                                          top of empathy, creative design is
                                          centered on observing product
                                          interaction, drawing conclusions based
                                          on research, and ensuring the user
                                          remains the focus of the final
                                          implementation.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Position yourself where your customers
                                          can see you.
                                        </h4>
                                        <p>
                                          The average person sees at least 5,000
                                          ads per day, so your brand’s
                                          advertisements can reach a large
                                          audience while conveying the unique
                                          selling points of your products or
                                          businesses in just a few minutes or
                                          seconds. Also known as the
                                          consideration stage of a consumer
                                          decision-making process, consumers
                                          compare the options available based on
                                          objective and subjective
                                          characteristics to determine which
                                          options best suit their needs.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  top-img boxraea">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            loading="lazy"
                                            decoding="async"
                                            width="1920"
                                            height="1080"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1.jpg"
                                            className="vc_single_image-img attachment-full lazyload"
                                            alt=""
                                            title="Social-Media-Icons-1"
                                            data-srcset="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1.jpg 1920w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1-300x169.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1-1024x576.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1-768x432.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1-1536x864.jpg 1536w"
                                            data-sizes="(max-width: 1920px) 100vw, 1920px"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            style={{
                                              SmushPlaceholderWidth: 1920,
                                              SmushPlaceholderAspectRatio:
                                                "1920/1080",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width="1920"
                                              height="1080"
                                              src="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1.jpg"
                                              class="vc_single_image-img attachment-full"
                                              alt=""
                                              title="Social-Media-Icons-1"
                                              srcset="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1.jpg 1920w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1-300x169.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1-1024x576.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1-768x432.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/Social-Media-Icons-1-1536x864.jpg 1536w"
                                              sizes="(max-width: 1920px) 100vw, 1920px"
                                            />
                                          </noscript>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-50 bgcolor-blue"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h3 className="whitehead">
                                          Know You’re Supported
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="boxraea">
                                          <img
                                            decoding="async"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/02/graphicDesignBG.jpg"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            className="lazyload"
                                            style={{
                                              SmushPlaceholderWidth: 1688,
                                              SmushPlaceholderAspectRatio:
                                                "1688/1125",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              src="https://adsdad.com/wp-content/uploads/2023/02/graphicDesignBG.jpg"
                                            />
                                          </noscript>
                                          <h5>
                                            Brand identity clarifies and forces
                                            focus
                                          </h5>
                                          <p>
                                            Brand identity becomes your purpose
                                            statement. Your positioning
                                            statement. Your north star. It
                                            becomes the thing you can always
                                            fall back on whenever a “great new
                                            opportunity” comes your way.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="boxraea">
                                          <img
                                            decoding="async"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/05/iStock-1125484894.jpg"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            className="lazyload"
                                            style={{
                                              SmushPlaceholderWidth: 1254,
                                              SmushPlaceholderAspectRatio:
                                                "1254/836",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              src="https://adsdad.com/wp-content/uploads/2023/05/iStock-1125484894.jpg"
                                            />
                                          </noscript>
                                          <h5>
                                            A powerful brand strategy is built
                                            on emotions
                                          </h5>
                                          <p>
                                            A brand built around tripping your
                                            customer’s emotions will always
                                            crush competitors discussing
                                            features and benefits.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="boxraea">
                                          <img
                                            decoding="async"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/05/communication1.jpg"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            className="lazyload"
                                            style={{
                                              SmushPlaceholderWidth: 1791,
                                              SmushPlaceholderAspectRatio:
                                                "1791/1072",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              src="https://adsdad.com/wp-content/uploads/2023/05/communication1.jpg"
                                            />
                                          </noscript>
                                          <h5>Word of Mouth</h5>
                                          <p>
                                            That’s the benefit of being aligned
                                            with your brand because what they’re
                                            saying is this: we make a promise to
                                            our customers, and those customers
                                            experience that promise. They are
                                            fulfilled and share such a strong
                                            experience with others.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-100"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  addverting">
                                      <div className="wpb_wrapper">
                                        <h4>Advertise your business on</h4>
                                      </div>
                                    </div>

                                    <div className="wpb_text_column wpb_content_element  addverting">
                                      <div className="wpb_wrapper">
                                        {/* Widget Shortcode */}
                                        <div
                                          id="custom_html-3"
                                          className="widget_text widget widget_custom_html widget-shortcode area-arbitrary "
                                        >
                                          <div className="textwidget custom-html-widget">
                                            <ul className="advertising">
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/reddit-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 162,
                                                      SmushPlaceholderAspectRatio:
                                                        "162/54",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/reddit-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/pinterest-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 169,
                                                      SmushPlaceholderAspectRatio:
                                                        "169/43",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/pinterest-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/linkedin-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 163,
                                                      SmushPlaceholderAspectRatio:
                                                        "163/43",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/linkedin-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/instagram-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 171,
                                                      SmushPlaceholderAspectRatio:
                                                        "171/48",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/instagram-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/facebook-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 172,
                                                      SmushPlaceholderAspectRatio:
                                                        "172/34",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/facebook-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/twitter-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 181,
                                                      SmushPlaceholderAspectRatio:
                                                        "181/34",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/twitter-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        {/* /Widget Shortcode */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid bg-color-grey"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <div className="addverting">
                                          <h4>
                                            Remarkable results htmlFor
                                            businesses
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <div className="business-portfolio">
                                          <div className="linkareaa">
                                            <a href="#">Clark Filters Direct</a>
                                          </div>
                                          <div className="platform-img">
                                            <img
                                              decoding="async"
                                              data-src="https://adsdad.com/wp-content/uploads/2023/01/img-clark-filter.png"
                                              src="https://adsdad.com/wp-content/uploads/2023/01/img-clark-filter.png"
                                              className=" lazyloaded"
                                              style={{
                                                SmushPlaceholderWidth: 600,
                                                SmushPlaceholderAspectRatio:
                                                  "600/395",
                                              }}
                                            />
                                          </div>
                                          <ul className="case-result-list list-unstyled">
                                            <li>
                                              <strong className="font-weight-bold h3">
                                                633%
                                              </strong>
                                              <strong className="d-block font-weight-normal">
                                                Increase in RPC
                                              </strong>
                                            </li>
                                            <li>
                                              <strong className="font-weight-bold h3">
                                                418%
                                              </strong>
                                              <strong className="d-block font-weight-normal">
                                                Increase in ROAS
                                              </strong>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <div className="business-portfolio">
                                          <div className="linkareaa">
                                            <a href="#">Clark Filters Direct</a>
                                          </div>
                                          <div className="platform-img">
                                            <img
                                              decoding="async"
                                              data-src="https://adsdad.com/wp-content/uploads/2023/01/img-clark-filter.png"
                                              src="https://adsdad.com/wp-content/uploads/2023/01/img-clark-filter.png"
                                              className=" lazyloaded"
                                              style={{
                                                SmushPlaceholderWidth: 600,
                                                SmushPlaceholderAspectRatio:
                                                  "600/395",
                                              }}
                                            />
                                          </div>
                                          <ul className="case-result-list list-unstyled">
                                            <li>
                                              <strong className="font-weight-bold h3">
                                                633%
                                              </strong>
                                              <strong className="d-block font-weight-normal">
                                                Increase in RPC
                                              </strong>
                                            </li>
                                            <li>
                                              <strong className="font-weight-bold h3">
                                                418%
                                              </strong>
                                              <strong className="d-block font-weight-normal">
                                                Increase in ROAS
                                              </strong>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-70 vc_row-no-padding"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid">
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html footerleft-area">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          A well-defined brand will guide
                                          advertising and marketing.
                                        </h4>
                                        <p>
                                          In essence, branding differentiates
                                          one business from another through a
                                          name, logo design, story, messaging,
                                          and so on.
                                        </p>
                                        <div className="flex-area">
                                          <div className="bxx">
                                            <span>
                                              - Build Trust With Customers
                                            </span>
                                            <span>
                                              - Gain Competitive Advantage
                                            </span>
                                            <span>
                                              - Connect With Your Audience
                                            </span>
                                            <span>
                                              - Brand Message and Values
                                            </span>
                                          </div>
                                          <div className="bxx">
                                            <span>
                                              - Create Emotional Connect
                                            </span>
                                            <span>- Build Brand Awareness</span>
                                            <span>
                                              - Creative Graphics and Videos
                                            </span>
                                            <span>
                                              - Make Company Recognizable
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_right">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            fetchpriority="high"
                                            decoding="async"
                                            width="1920"
                                            height="1225"
                                            src="https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding.jpg"
                                            className="vc_single_image-img attachment-full"
                                            alt=""
                                            title="Brand Branding Marketing Commercial Name Concept"
                                            srcSet="https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding.jpg 1920w, https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding-300x191.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding-1024x653.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding-768x490.jpg 768w, https://adsdad.com/wp-content/uploads/2023/02/logos-and-branding-1536x980.jpg 1536w"
                                            sizes="(max-width: 1920px) 100vw, 1920px"
                                          />
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid faq-bottom-padding"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html title-faq">
                                      <div className="wpb_wrapper">
                                        <h3>
                                          Questions to Ask When Hiring a
                                          Branding Agency
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>What is Branding?</h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Branding is creating and maintaining
                                            a recognizable product, service, or
                                            company identity. It involves
                                            creating a unique name, logo, or
                                            slogan that customers associate with
                                            a particular organization. Branding
                                            is a critical part of marketing and
                                            helps to differentiate a company or
                                            product from its competitors. It
                                            also helps to establish customer
                                            loyalty and build a strong
                                            reputation htmlFor a product or
                                            company.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>How does Branding work?</h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Branding is a marketing practice
                                            that involves creating a unique
                                            name, symbol, or design that helps
                                            to differentiate a product or
                                            service from others. It also helps
                                            to create an emotional connection
                                            between the company and its
                                            customers. Branding creates an image
                                            of the company that customers can
                                            relate to and remember. It can
                                            involve creating a logo, slogan, or
                                            other visual elements that evoke a
                                            feeling or emotion about the
                                            company. Branding also consists in
                                            communicating the company’s message
                                            to customers consistently and
                                            meaningfully. This helps to create a
                                            lasting impression of the company
                                            and its products or services.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          Why is branding important htmlFor
                                          businesses?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Branding is essential for businesses
                                            because it allows them to create an
                                            identity and stand out. It helps
                                            customers recognize their products
                                            and services and builds trust in the
                                            company. A strong brand also helps
                                            businesses establish relationships
                                            with their customers and can
                                            increase loyalty and customer
                                            retention. It can also help
                                            companies to create a unique and
                                            memorable customer experience and
                                            make their products more desirable.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          How can branding help my business
                                          stand out?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Branding can help your business
                                            stand out by creating a unique
                                            identity that customers will
                                            recognize and remember. 
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            A strong brand will create greater
                                            customer loyalty, attract more
                                            customers, and establish a strong
                                            presence in the market. Branding can
                                            also differentiate you from
                                            competitors and build trust with
                                            customers. Finally, branding can
                                            increase your visibility and make it
                                            easier htmlFor customers to find and
                                            remember your business.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          How much do Branding services cost in
                                          India?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            The cost of branding services in
                                            India can vary greatly depending on
                                            the scope of the project and the
                                            type of services required.
                                            Generally, branding services range
                                            in cost from around Rs. 10,000 to
                                            Rs. 1,00,000 or more.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          Why Should I hire Branding Agency?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            A branding agency can help you
                                            create a strategic identity and
                                            positioning for your business that
                                            is consistent and recognizable
                                            across all channels. They can help
                                            you develop a brand strategy, create
                                            a unique logo and visual identity,
                                            and create a strong message that
                                            resonates with your target audience.
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            They also help you create the right
                                            content, create an effective
                                            website, and develop effective
                                            digital campaigns. Branding agencies
                                            are experienced professionals who
                                            know the ins and outs of the
                                            branding process and can help you
                                            create a distinct and successful
                                            identity htmlFor your business.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          Why Choose NavUday Solution Digital Branding
                                          Services?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            NavUday Solution Digital Branding Services are
                                            designed to help you create a
                                            powerful, recognizable brand
                                            identity that will resonate with
                                            your target audience. Our team of
                                            experienced professionals provides
                                            comprehensive branding solutions
                                            tailored to meet your business’s
                                            unique needs. We use research-driven
                                            data and innovative strategies to
                                            create a customized, comprehensive
                                            branding package that includes logo
                                            design, website development and
                                            content creation, and online and
                                            offline marketing tactics. We aim to
                                            help you build a strong,
                                            recognizable brand that will stand
                                            out in the competitive marketplace
                                            and leave a lasting impression on
                                            your customers. We are committed to
                                            helping you create a brand to drive
                                            growth, build loyalty, and increase
                                            your bottom line.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid testimonials-area"
                      style={{
                        position: "relative",
                        left: 0,
                        boxSizing: "border-box",
                        width: 415,
                        maxWidth: 415,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                      <div className="wpb_wrapper">
                                        <h5>
                                          What <span>Our Client</span> Say's
                                        </h5>
                                        <p>
                                          Serving our clients with top-notch
                                          results is our major priority, htmlFor
                                          each expert at NavUday Solution Digital, our
                                          motive is to build a strong
                                          relationship with our client for
                                          equivalent growth and success.
                                        </p>
                                      </div>
                                    </div>

                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                      <div className="wpb_wrapper">
                                        <div
                                          className="white"
                                          style={{
                                            background: "rgba(0,0,0,0)",
                                            border: "solid 0px rgba(0,0,0,0)",
                                            borderRadius: 0,
                                            padding: "0px 0px 0px 0px",
                                          }}
                                        >
                                          <div
                                            id="slider_115"
                                            className="owl-carousel sa_owl_theme autohide-arrows owl-loaded owl-drag"
                                            data-slider-id="slider_115"
                                            style={{ visibility: "visible" }}
                                          >
                                            <div className="owl-stage-outer">
                                              <div
                                                className="owl-stage"
                                                style={{
                                                  transform:
                                                    "translate3d(-1065px, 0px, 0px)",
                                                  transition: "0.2s",
                                                  width: 2840,
                                                }}
                                              >
                                                <div
                                                  className="owl-item cloned"
                                                  style={{ width: 355 }}
                                                >
                                                  <div
                                                    id="slider_115_slide03"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          NavUday Solution Digital's
                                                          website design
                                                          services are
                                                          top-notch. Their team
                                                          is professional,
                                                          talented and very
                                                          responsive. They
                                                          created a beautiful
                                                          website that perfectly
                                                          represents our brand
                                                          and effectively
                                                          communicates our
                                                          message to our target
                                                          audience. We highly
                                                          recommend them!
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Shubham Chabbra (Sketra)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item cloned"
                                                  style={{ width: 355 }}
                                                >
                                                  <div
                                                    id="slider_115_slide04"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          NavUday Solution Experts helped
                                                          us take our home decor
                                                          marketplace to the
                                                          next level. Their team
                                                          has been instrumental
                                                          in improving our
                                                          search engine
                                                          rankings, increasing
                                                          our organic traffic
                                                          and driving more
                                                          sales. Their
                                                          professionalism,
                                                          support and expertise
                                                          have been a great
                                                          asset to our business.
                                                          We highly recommend
                                                          them!"
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Manjula (Good Homes
                                                        Company)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item"
                                                  style={{ width: 355 }}
                                                >
                                                  <div
                                                    id="slider_115_slide01"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          They have been a game
                                                          changer htmlFor our
                                                          cleaning services
                                                          business. Their team
                                                          has helped us generate
                                                          qualified leads
                                                          through targeted
                                                          campaigns. Their
                                                          professionalism and
                                                          expertise in lead
                                                          generation have been
                                                          invaluable to our
                                                          business. We highly
                                                          recommend them!"
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Alice Allen(Dirt2Tidy)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item active"
                                                  style={{ width: 355 }}
                                                >
                                                  <div
                                                    id="slider_115_slide02"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          We are extremely
                                                          satisfied with the
                                                          results we have seen
                                                          from NavUday Solution Digital's
                                                          SEO services. They
                                                          have helped us
                                                          increase our online
                                                          visibility and drive
                                                          more traffic to our
                                                          website. Their team is
                                                          easy to work with and
                                                          always goes above and
                                                          beyond to exceed our
                                                          expectations
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Juka (Juka Organics)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item"
                                                  style={{ width: 355 }}
                                                >
                                                  <div
                                                    id="slider_115_slide03"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          NavUday Solution Digital's
                                                          website design
                                                          services are
                                                          top-notch. Their team
                                                          is professional,
                                                          talented and very
                                                          responsive. They
                                                          created a beautiful
                                                          website that perfectly
                                                          represents our brand
                                                          and effectively
                                                          communicates our
                                                          message to our target
                                                          audience. We highly
                                                          recommend them!
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Shubham Chabbra (Sketra)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item"
                                                  style={{ width: 355 }}
                                                >
                                                  <div
                                                    id="slider_115_slide04"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          NavUday Solution Experts helped
                                                          us take our home decor
                                                          marketplace to the
                                                          next level. Their team
                                                          has been instrumental
                                                          in improving our
                                                          search engine
                                                          rankings, increasing
                                                          our organic traffic
                                                          and driving more
                                                          sales. Their
                                                          professionalism,
                                                          support and expertise
                                                          have been a great
                                                          asset to our business.
                                                          We highly recommend
                                                          them!"
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Manjula (Good Homes
                                                        Company)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item cloned"
                                                  style={{ width: 355 }}
                                                >
                                                  <div
                                                    id="slider_115_slide01"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          They have been a game
                                                          changer htmlFor our
                                                          cleaning services
                                                          business. Their team
                                                          has helped us generate
                                                          qualified leads
                                                          through targeted
                                                          campaigns. Their
                                                          professionalism and
                                                          expertise in lead
                                                          generation have been
                                                          invaluable to our
                                                          business. We highly
                                                          recommend them!"
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Alice Allen(Dirt2Tidy)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item cloned"
                                                  style={{ width: 355 }}
                                                >
                                                  <div
                                                    id="slider_115_slide02"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          We are extremely
                                                          satisfied with the
                                                          results we have seen
                                                          from NavUday Solution Digital's
                                                          SEO services. They
                                                          have helped us
                                                          increase our online
                                                          visibility and drive
                                                          more traffic to our
                                                          website. Their team is
                                                          easy to work with and
                                                          always goes above and
                                                          beyond to exceed our
                                                          expectations
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Juka (Juka Organics)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="owl-nav">
                                              <button
                                                type="button"
                                                role="presentation"
                                                className="owl-prev"
                                              >
                                                Previous
                                              </button>
                                              <button
                                                type="button"
                                                role="presentation"
                                                className="owl-next"
                                              >
                                                Next
                                              </button>
                                            </div>
                                            <div className="owl-dots disabled" />
                                            <div className="owl-thumbs" />
                                          </div>
                                        </div>
   
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                  </div>
                  {/* .entry-content */}
                </article>
                {/* #post-## */}
              </div>
              {/* #main */}
            </section>
            {/* #primary */}
          </div>
          {/* .row */}
        </div>
        {/* .container */}
      </div>
    </>
  );
}

export default Brandbuild;
