import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
  return (
    
<>




<a className="skip-link screen-reader-text" href="#content">Skip to content</a>
        <header id="masthead" className="site-header navbar-static-top navbar-light" role="banner">
            <div className="wewidthh">
                <nav className="navbar navbar-expand-xl p-0">
                    <div className="navbar-brand">
                        <a href="/">
                            <img data-src="" alt="company logo  " src="" className=" lazyloaded" style={{ SmushPlaceholderWidth: 2560, SmushPlaceholderAspectRatio: "2560/964" }} /><noscript><img
                                    src=""
                                    alt="Best Digital Marketing Agency in Delhi"/></noscript>
                        </a>

                    </div>
                    <div className="mobshow skyppp">
                        <a href="#" target="_blank"><i className="fab fa-skype" /></a>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-nav" aria-controls="" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                        
                    <div id="main-nav" className="collapse navbar-collapse justify-content-end">
                        <ul id="menu-menu-1" className="navbar-nav">
                            <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-133" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-72 current_page_item active menu-item-133 nav-item">
                                <a title="Home" href="https://navudaysolution.dhancash.in/" className="nav-link" aria-current="page">Home</a>
                            </li>
                            <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-337" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-337 nav-item">
                                <a title="About Us" href="/about" className="nav-link">About Us</a>
                            </li>
                            <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-30" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-30 nav-item">
                                <div className="dg-btn"><i className="fa fa-angle-down" aria-hidden="true" /></div><a title="Services" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle nav-link" id="menu-item-dropdown-30">Services</a>
                                <ul className="dropdown-menu" aria-labelledby="menu-item-dropdown-30" role="menu">
                                    <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-323" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-323 nav-item">
                                        <a title="SEO Services" href="https://navudaysolution.dhancash.in/seopage" className="dropdown-item">SEO Services</a>
                                    </li>
                                    <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-322" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-322 nav-item">
                                        <a title="PPC Services" href="https://navudaysolution.dhancash.in/ppc" className="dropdown-item">PPC Services</a>
                                    </li>
                                    <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-1265" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1265 nav-item">
                                        <a title="Google Ads" href="https://navudaysolution.dhancash.in/googleads" className="dropdown-item">Google Ads</a>
                                    </li>
                                    <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-321" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-321 nav-item">
                                        <a title="Brand Building" href="https://navudaysolution.dhancash.in/brandbuild" className="dropdown-item">Brand Building</a>
                                    </li>
                                    <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-324" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-324 nav-item">
                                        <a title="Social Media Marketing" href="https://navudaysolution.dhancash.in/socialmedia" className="dropdown-item">Social Media Marketing</a>
                                    </li>
                                    <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-325" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-325 nav-item">
                                        <a title="Web Design\xA0&\xA0Development" href="https://navudaysolution.dhancash.in/webdesign" className="dropdown-item">Web Design & Development</a>
                                    </li>
                                </ul>
                            </li>

                           
                            <li itemScope itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-330" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-330 nav-item">
                                <a title="Contact Us" href="https://navudaysolution.dhancash.in/contact" className="nav-link">Contact
                                    Us</a>
                            </li>
                        </ul>
                    </div>
                    <script>{`
                        $(document).ready(function () {
                            $('.navbar-nav li.dropdown').on('click', function () {
                                $(this).toggleClass('mega-toggle-on');
                            });
                        });
                    `}</script>
                    <script>{`
                        $(document).ready(function () {
                            if ($('.navbar-nav li.dropdown>a').length) {
                                $('.navbar-nav li.dropdown>a').before('<div classNameName="dg-btn"><i class="fa fa-angle-down" aria-hidden="true"></i></div>');
                            }
                        });
                    `}</script>
                </nav>
                <a href="https://navudaysolution.dhancash.in/contact" className="project-rr deskshow pum-trigger" style={{ cursor: "pointer" }}> Start Your Project</a>
                <script>{`
                    $(window).scroll(function () {
                        if ($(this).scrollTop() > 1) {
                            $('header').addClass("sticky");
                        }
                        else {
                            $('header').removeClass("sticky");
                        }
                    });
                `}</script>

            </div>
        </header>


















</>










  )
}

export default Navbar
