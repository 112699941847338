import React from 'react'
import Navbar from '../Component/Navbar'

function Home() {
  return (
    <>
    
    
    
    <>
    
    
    <div id="content" className="site-content">
            <div className="container">
                <div className="row">

                    <section id="primary" className="content-area col-sm-12">
                        <div id="main" className="site-main" role="main">


                            <article id="post-72" className="post-72 page type-page status-publish hentry">

                                <div className="entry-content">
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid vc_row-no-padding" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 320, maxWidth: 320 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                                                        <div className="wpb_wrapper">
                                                                            <h1 className="head-rr">Digital Marketing Agency
                                                                            </h1>
                                                                            <div className="slider">
                                                                                <div id="text">Design and Deplopment </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className="wpb_raw_code wpb_raw_js">
                                                                    <div className="wpb_wrapper">
                                                                    

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html mybutton">
                                                                    <div className="wpb_wrapper">
                                                                        <ul className="certified-area">
                                                                            <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"/></noscript>
                                                                            </li>
                                                                            <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"/></noscript>
                                                                            </li>
                                                                            <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"/></noscript>
                                                                            </li>
                                                                            <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"/></noscript>
                                                                            </li>
                                                                            <li><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"/></noscript>
                                                                            </li>

                                                                        </ul>
                                                                        <div className="popmake-243 pum-trigger project-rr" style={{ cursor: "pointer" }}>
                                                                            <a href="#">Click Here To Get A Free
                                                                                Proposal</a>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row-full-width vc_clearfix" />
                                <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-top-bot lightorange" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 320, maxWidth: 320 }}>
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth pt">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>Leading Digital Marketing Agency in Delhi
                                                                            NCR, India<br />

                                                                        </h2>
                                                                    </div>
                                                                </div>

                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="notes">
                                                                            <ul className="note_list">
                                                                                <li className="active" style={{ display: "list-item" }}>Pay Per
                                                                                    Click Advertising </li>
                                                                                <li className="" style={{ display: "none" }}>
                                                                                    Search Engine Optimization </li>
                                                                                <li className="" style={{ display: "none" }}>Web
                                                                                    Design & Development </li>
                                                                                <li className="" style={{ display: "none" }}>
                                                                                    Social Media Marketing </li>
                                                                                <li className="" style={{ display: "none" }}>
                                                                                    Google Ads </li>
                                                                                <li className="" style={{ display: "none" }}>
                                                                                    Brand Building</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wpb_raw_code wpb_raw_js">
                                                                    <div className="wpb_wrapper">
                                                                      
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                                                    <div className="deskshow wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_text_column wpb_content_element  left-images-area">
                                                                    <div className="wpb_wrapper">
                                                                        <p><img fetchpriority="high" decoding="async" className="alignnone" src="https://adsdad.com/wp-content/uploads/2023/02/Social-Media-size-logo-PNG.png" alt="logo icon" width="1080" height="1080" /></p>
                                                                        <div className="rqst-main-div">
                                                                            <div className="rqst-call-div">
                                                                                <p className="click">Click here to get a
                                                                                    <span className="">call back</span>
                                                                                </p>
                                                                                <p><a className="see pum-trigger" href="#" style={{ cursor: "pointer" }}>request
                                                                                        a call back</a></p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home justify-text">
                                                                    <div className="wpb_wrapper">
                                                                        <p>NavUday Solution Digital is a leading digital marketing
                                                                            agency in Delhi that helps you to beat your
                                                                            competitor with PPC, SEO, web design,
                                                                            content marketing, and so much more. Helping
                                                                            more than 2k+ companies to grow their
                                                                            business across the world, notably in India,
                                                                            US, UK, Australia, Canada, Dubai, and South
                                                                            Africa. Over the last three years, we have
                                                                            delivered outstanding results htmlFor our
                                                                            clients.
                                                                            Our team is made up of experienced
                                                                            marketers, designers, content creators, and
                                                                            developers who have the knowledge and
                                                                            expertise to make sure your digital
                                                                            campaigns are a success.</p>
                                                                    </div>
                                                                </div>

                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <div className="box-area">
                                                                                    <p>We Have Over</p>
                                                                                    <h5>3+</h5>
                                                                                    <span>Years of Experience</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="box-area">
                                                                                    <p>Expertise In</p>
                                                                                    <h5>12+</h5>
                                                                                    <span>Technologies</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <div className="box-area">
                                                                                    <p>We Have Done</p>
                                                                                    <h5>2K+</h5>
                                                                                    <span>Projects & Counting</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="box-area">
                                                                                    <p>Clients In</p>
                                                                                    <h5>28+</h5>
                                                                                    <span>Countries</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row-full-width vc_clearfix" />
                                <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-top-bot" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 320, maxWidth: 320 }}>
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-hh">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="share-details">
                                                                            <p>Now! Ready to <span>SHARE</span> Your
                                                                                Project Details?</p><a className="popmake-243 pum-trigger project-rr" style={{ cursor: "pointer" }}>Click here to
                                                                                share details!</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>Digital Marketing Company in <span>Delhi
                                                                                NCR</span></h2>
                                                                        <p>In an ever-competitive and expanding market,
                                                                            it’s getting harder and harder to cut
                                                                            through the noise and reach your target
                                                                            audience. Ready to grow your business by
                                                                            partnering with us</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth padding-top-25">
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp wpb_start_animation animated">

                                                                    <figure className="wpb_wrapper vc_figure">
                                                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img decoding="async" width="495" height="522" data-src="https://adsdad.com/wp-content/uploads/2023/01/seo-service.jpg" className="vc_single_image-img attachment-full lazyloaded" alt="" title="seo-service" data-srcset="https://adsdad.com/wp-content/uploads/2023/01/seo-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/seo-service-284x300.jpg 284w" data-sizes="(max-width: 495px) 100vw, 495px" src="https://adsdad.com/wp-content/uploads/2023/01/seo-service.jpg" style={{ SmushPlaceholderWidth: 495, SmushPlaceholderAspectRatio: "495/522" }} sizes="(max-width: 495px) 100vw, 495px" srcSet="https://adsdad.com/wp-content/uploads/2023/01/seo-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/seo-service-284x300.jpg 284w" /><noscript><img
                                                                                    decoding="async" width="495"
                                                                                    height="522"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/01/seo-service.jpg"
                                                                                    className="vc_single_image-img attachment-full"
                                                                                    alt="" title="seo-service"
                                                                                    srcSet="https://adsdad.com/wp-content/uploads/2023/01/seo-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/seo-service-284x300.jpg 284w"
                                                                                    sizes="(max-width: 495px) 100vw, 495px" /></noscript>
                                                                        </div>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html marketing-box">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>Search Engine Optimization</h2>
                                                                        <p>As one of the leading SEO agency in Delhi, we
                                                                            believe that any SEO activity should begin
                                                                            with an examination of the current situation
                                                                            and its relevance to business goals. Our SEO
                                                                            experts will develop specific SEO strategy
                                                                            htmlFor you to overcome market challenges and
                                                                            customize your content to help you rank
                                                                            higher in keyword search.</p>

                                                                        <span>- Website SEO Audit</span>
                                                                        <span>- On-page & Off-page SEO</span>
                                                                        <span>- Blog & Article Creation</span>
                                                                        <span>- Website Backlink Building</span>

                                                                        <a href="/seopage"><button className="button-74" role="button">Get Top
                                                                                Ranking</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth padding-top-25 rev">
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html marketing-box black">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>PPC Advertising</h2>
                                                                        <p>As one of the leading PPC company in Delhi,
                                                                            our PPC expert will help you reach the right
                                                                            audience htmlFor each campaign and find an
                                                                            innovative way to connect you to them. Scale
                                                                            up leads and sales along with decrease in
                                                                            acquisition cost with our ppc management
                                                                            services.</p>

                                                                        <span>- Google Ads</span>
                                                                        <span>- Meta Ads</span>
                                                                        <span>- Ecommerce Ads</span>
                                                                        <span>- Remarketing Ads</span>

                                                                        <a href="/googleads"><button className="button-74" role="button">Generate
                                                                                Leads/Sales</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp wpb_start_animation animated">

                                                                    <figure className="wpb_wrapper vc_figure">
                                                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img decoding="async" width="1500" height="1163" data-src="https://adsdad.com/wp-content/uploads/2023/05/ppc-img.jpg" className="vc_single_image-img attachment-full lazyloaded" alt="" title="ppc-img" data-srcset="https://adsdad.com/wp-content/uploads/2023/05/ppc-img.jpg 1500w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-300x233.jpg 300w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-1024x794.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-768x595.jpg 768w" data-sizes="(max-width: 1500px) 100vw, 1500px" src="https://adsdad.com/wp-content/uploads/2023/05/ppc-img.jpg" style={{ SmushPlaceholderWidth: 1500, SmushPlaceholderAspectRatio: "1500/1163" }} sizes="(max-width: 1500px) 100vw, 1500px" srcSet="https://adsdad.com/wp-content/uploads/2023/05/ppc-img.jpg 1500w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-300x233.jpg 300w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-1024x794.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-768x595.jpg 768w" /><noscript><img
                                                                                    decoding="async" width="1500"
                                                                                    height="1163"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/05/ppc-img.jpg"
                                                                                    className="vc_single_image-img attachment-full"
                                                                                    alt="" title="ppc-img"
                                                                                    srcSet="https://adsdad.com/wp-content/uploads/2023/05/ppc-img.jpg 1500w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-300x233.jpg 300w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-1024x794.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/05/ppc-img-768x595.jpg 768w"
                                                                                    sizes="(max-width: 1500px) 100vw, 1500px" /></noscript>
                                                                        </div>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth padding-top-25">
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp wpb_start_animation animated">

                                                                    <figure className="wpb_wrapper vc_figure">
                                                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img loading="lazy" decoding="async" width="495" height="522" data-src="https://adsdad.com/wp-content/uploads/2023/01/web-development.jpg" className="vc_single_image-img attachment-full lazyloaded" alt="" title="web-development" data-srcset="https://adsdad.com/wp-content/uploads/2023/01/web-development.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/web-development-284x300.jpg 284w" data-sizes="(max-width: 495px) 100vw, 495px" src="https://adsdad.com/wp-content/uploads/2023/01/web-development.jpg" style={{ SmushPlaceholderWidth: 495, SmushPlaceholderAspectRatio: "495/522" }} sizes="(max-width: 495px) 100vw, 495px" srcSet="https://adsdad.com/wp-content/uploads/2023/01/web-development.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/web-development-284x300.jpg 284w" /><noscript><img
                                                                                    loading="lazy" decoding="async"
                                                                                    width="495" height="522"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/01/web-development.jpg"
                                                                                    className="vc_single_image-img attachment-full"
                                                                                    alt="" title="web-development"
                                                                                    srcSet="https://adsdad.com/wp-content/uploads/2023/01/web-development.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/web-development-284x300.jpg 284w"
                                                                                    sizes="(max-width: 495px) 100vw, 495px" /></noscript>
                                                                        </div>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html marketing-box">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>Website Design & Development</h2>
                                                                        <p>As one of the leading web design agency in
                                                                            Delhi, Our website design approach focuses
                                                                            on creating responsive, seo-friendly, eye
                                                                            catching website that bring customer from
                                                                            all over the world. We look forward to
                                                                            talking with our clients about their ideas
                                                                            and then working with our team to come up
                                                                            with solutions to make it happen!</p>

                                                                        <span>- WordPress Design</span>
                                                                        <span>- Shopify Design</span>
                                                                        <span>- Custom Development</span>
                                                                        <span>- Website Maintenance</span>

                                                                        <a href="/webdesign"><button className="button-74" role="button">Get Your
                                                                                Dream Website</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth padding-top-25 rev">
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html marketing-box black">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>Social Media Marketing</h2>
                                                                        <p>As one of Delhi's leading digital marketing
                                                                            agency, our social media experts believe in
                                                                            listening to your audience and developing
                                                                            platform-specific social media strategies.
                                                                            With the help of our social media experts,
                                                                            we believe in creating engaging content that
                                                                            enhances our audience interaction and helps
                                                                            our message go viral.</p>

                                                                        <span>- Content Creation</span>
                                                                        <span>- Stories & Short Video
                                                                            Generations</span>
                                                                        <span>- Social Media Strategy</span>
                                                                        <span>- Social Media Management</span>

                                                                        <a href="/brandbuild"><button className="button-74" role="button">Build A
                                                                                Powerful Brand</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp wpb_start_animation animated">

                                                                    <figure className="wpb_wrapper vc_figure">
                                                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img loading="lazy" decoding="async" width="495" height="522" data-src="https://adsdad.com/wp-content/uploads/2023/01/social-media-service.jpg" className="vc_single_image-img attachment-full lazyloaded" alt="" title="social-media-service" data-srcset="https://adsdad.com/wp-content/uploads/2023/01/social-media-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/social-media-service-284x300.jpg 284w" data-sizes="(max-width: 495px) 100vw, 495px" src="https://adsdad.com/wp-content/uploads/2023/01/social-media-service.jpg" style={{ SmushPlaceholderWidth: 495, SmushPlaceholderAspectRatio: "495/522" }} sizes="(max-width: 495px) 100vw, 495px" srcSet="https://adsdad.com/wp-content/uploads/2023/01/social-media-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/social-media-service-284x300.jpg 284w" /><noscript><img
                                                                                    loading="lazy" decoding="async"
                                                                                    width="495" height="522"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/01/social-media-service.jpg"
                                                                                    className="vc_single_image-img attachment-full"
                                                                                    alt="" title="social-media-service"
                                                                                    srcSet="https://adsdad.com/wp-content/uploads/2023/01/social-media-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/social-media-service-284x300.jpg 284w"
                                                                                    sizes="(max-width: 495px) 100vw, 495px" /></noscript>
                                                                        </div>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_single_image wpb_content_element vc_align_left wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp wpb_start_animation animated">

                                                                    <figure className="wpb_wrapper vc_figure">
                                                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img loading="lazy" decoding="async" width="495" height="522" data-src="https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service.jpg" className="vc_single_image-img attachment-full lazyloaded" alt="" title="Google-Ads-service" data-srcset="https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service-284x300.jpg 284w" data-sizes="(max-width: 495px) 100vw, 495px" src="https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service.jpg" style={{ SmushPlaceholderWidth: 495, SmushPlaceholderAspectRatio: "495/522" }} sizes="(max-width: 495px) 100vw, 495px" srcSet="https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service-284x300.jpg 284w" /><noscript><img
                                                                                    loading="lazy" decoding="async"
                                                                                    width="495" height="522"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service.jpg"
                                                                                    className="vc_single_image-img attachment-full"
                                                                                    alt="" title="Google-Ads-service"
                                                                                    srcSet="https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/Google-Ads-service-284x300.jpg 284w"
                                                                                    sizes="(max-width: 495px) 100vw, 495px" /></noscript>
                                                                        </div>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html marketing-box">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>Google Ads</h2>
                                                                        <p>As one of the leading digital marketing
                                                                            agency in Delhi, our google ads experts
                                                                            believe in knowing to your audience and
                                                                            developing platform-specific google ads
                                                                            strategies. With the help of our google ads
                                                                            experts, we believe in creating responsive
                                                                            ads that will improve your interaction with
                                                                            your audience and gives more conversions.
                                                                        </p>

                                                                        <span>- Search Ads </span>
                                                                        <span>- Display Ads</span>
                                                                        <span>- Video Ads</span>
                                                                        <span>- Shopping Ads</span>
                                                                        <span>- Remarketing Ads</span>

                                                                        <a href="/ppc"><button className="button-74" role="button">Generate
                                                                                Leads/Sales</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth padding-top-25 rev">
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html marketing-box black">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>Branding</h2>
                                                                        <p>As one of the best branding agency in Delhi
                                                                            and a leading digital marketing agency, we
                                                                            believe in understanding your brand equity
                                                                            in the minds of your audience. Develop the
                                                                            right branding strategy to make your
                                                                            portfolio well known to your audience.</p>

                                                                        <span>- Logo Designing</span>
                                                                        <span>- Graphic Designing</span>
                                                                        <span>- Brochure Designing</span>
                                                                        <span>- Business Cards</span>

                                                                        <a href="/brandbuild"><button className="button-74" role="button">Get A
                                                                                Strong Branding</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp wpb_start_animation animated">

                                                                    <figure className="wpb_wrapper vc_figure">
                                                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                            <img loading="lazy" decoding="async" width="495" height="522" data-src="https://adsdad.com/wp-content/uploads/2023/01/branding.jpg" className="vc_single_image-img attachment-full lazyloaded" alt="" title="branding" data-srcset="https://adsdad.com/wp-content/uploads/2023/01/branding.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/branding-284x300.jpg 284w" data-sizes="(max-width: 495px) 100vw, 495px" src="https://adsdad.com/wp-content/uploads/2023/01/branding.jpg" style={{ SmushPlaceholderWidth: 495, SmushPlaceholderAspectRatio: "495/522" }} sizes="(max-width: 495px) 100vw, 495px" srcSet="https://adsdad.com/wp-content/uploads/2023/01/branding.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/branding-284x300.jpg 284w" /><noscript><img
                                                                                    loading="lazy" decoding="async"
                                                                                    width="495" height="522"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/01/branding.jpg"
                                                                                    className="vc_single_image-img attachment-full"
                                                                                    alt="" title="branding"
                                                                                    srcSet="https://adsdad.com/wp-content/uploads/2023/01/branding.jpg 495w, https://adsdad.com/wp-content/uploads/2023/01/branding-284x300.jpg 284w"
                                                                                    sizes="(max-width: 495px) 100vw, 495px" /></noscript>
                                                                        </div>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row-full-width vc_clearfix" />
                                <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid industriess-area" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 320, maxWidth: 320 }}>
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                    <div className="wpb_wrapper">
                                                                        <h2> <span>INDUSTRIES</span> THAT <br />ADSDAD
                                                                            DIGITAL SERVES WITH COMPETENCY</h2>
                                                                        <p>Deep research of each industry and particular
                                                                            knowledge of digital marketing will help you
                                                                            boost your business with ease and build
                                                                            strong competition in the market.</p>

                                                                    </div>
                                                                </div>

                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="white" style={{ background: "rgba(0,0,0,0)", border: "solid 0px rgba(0,0,0,0)", borderRadius: 0, padding: "0px 0px 0px 0px" }}>
                                                                            <div id="slider_104" className="owl-carousel sa_owl_theme autohide-arrows owl-loaded owl-drag" data-slider-id="slider_104" style={{ visibility: "visible" }}>






                                                                                <div className="owl-stage-outer">
                                                                                    <div className="owl-stage" style={{ transform: "translate3d(-780px, 0px, 0px)", transition: "0.2s", width: 1560 }}>
                                                                                        <div className="owl-item cloned" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/food-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/food-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/food-icon.png"/></noscript>
                                                                                                    <span>Food &
                                                                                                        Beverage</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item cloned" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide05" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/goverment-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/goverment-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/goverment-icon.png"/></noscript>
                                                                                                    <span>Government</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item cloned" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide06" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/repair-ixon.png" src="https://adsdad.com/wp-content/uploads/2022/12/repair-ixon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/repair-ixon.png"/></noscript>
                                                                                                    <span>Automotive</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/real-estate-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/real-estate-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/real-estate-icon.png"/></noscript>
                                                                                                    <span>Real
                                                                                                        Estate</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/b2b-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/b2b-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/b2b-icon.png"/></noscript>
                                                                                                    <span>B2B</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/school-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/school-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/school-icon.png"/></noscript>
                                                                                                    <span>School &
                                                                                                        Institutions</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item active" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/food-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/food-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/food-icon.png"/></noscript>
                                                                                                    <span>Food &
                                                                                                        Beverage</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item active" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide05" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/goverment-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/goverment-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/goverment-icon.png"/></noscript>
                                                                                                    <span>Government</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide06" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/repair-ixon.png" src="https://adsdad.com/wp-content/uploads/2022/12/repair-ixon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/repair-ixon.png"/></noscript>
                                                                                                    <span>Automotive</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item cloned" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/real-estate-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/real-estate-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/real-estate-icon.png"/></noscript>
                                                                                                    <span>Real
                                                                                                        Estate</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item cloned" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/b2b-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/b2b-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/b2b-icon.png"/></noscript>
                                                                                                    <span>B2B</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item cloned" style={{ width: 130 }}>
                                                                                            <div id="slider_104_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 3%" }}>
                                                                                                <div className="box-indus">
                                                                                                    <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/school-icon.png" src="https://adsdad.com/wp-content/uploads/2022/12/school-icon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 60, SmushPlaceholderAspectRatio: "60/60" }} /><noscript><img
                                                                                                            decoding="async"
                                                                                                            src="https://adsdad.com/wp-content/uploads/2022/12/school-icon.png"/></noscript>
                                                                                                    <span>School &
                                                                                                        Institutions</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="owl-nav"><button type="button" role="presentation" className="owl-prev">Previous</button><button type="button" role="presentation" className="owl-next">Next</button>
                                                                                </div>
                                                                                <div className="owl-dots disabled" />
                                                                                <div className="owl-thumbs" />
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row-full-width vc_clearfix" />
                                <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-top-bot" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 320, maxWidth: 320 }}>
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                    <div className="wpb_wrapper">
                                                                        <h2><span>BRANDS WE WORK WITH</span></h2>
                                                                        <p>NavUday Solution experts help each brand and industry
                                                                            to build and raise their brand image,
                                                                            evaluability, and authenticity from scratch.
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html brand">
                                                                    <div className="wpb_wrapper">
                                                                        <marquee><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/mothers-pride.png" src="https://adsdad.com/wp-content/uploads/2023/02/mothers-pride.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/mothers-pride.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/paras-healthcare.png" src="https://adsdad.com/wp-content/uploads/2023/02/paras-healthcare.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/paras-healthcare.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/twachaa.png" src="https://adsdad.com/wp-content/uploads/2023/02/twachaa.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/twachaa.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/m3m.png" src="https://adsdad.com/wp-content/uploads/2023/02/m3m.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/m3m.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/IFFT1.png" src="https://adsdad.com/wp-content/uploads/2023/02/IFFT1.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/140" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/IFFT1.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/Go-Mechanic.png" src="https://adsdad.com/wp-content/uploads/2023/02/Go-Mechanic.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/Go-Mechanic.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/BHUTANI.png" src="https://adsdad.com/wp-content/uploads/2023/02/BHUTANI.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/BHUTANI.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/sketra.png" src="https://adsdad.com/wp-content/uploads/2023/02/sketra.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/sketra.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/shapoorji.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/shapoorji.png"/></noscript>
                                                                            <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/02/stanza-living.png" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload" style={{ SmushPlaceholderWidth: 156, SmushPlaceholderAspectRatio: "156/104" }} /><noscript><img
                                                                                    decoding="async"
                                                                                    src="https://adsdad.com/wp-content/uploads/2023/02/stanza-living.png"/></noscript>
                                                                        </marquee>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row-full-width vc_clearfix" />
                                <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid testimonials-area" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 320, maxWidth: 320 }}>
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                                        <div className="vc_column-inner">
                                                            <div className="wpb_wrapper">
                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                    <div className="wpb_wrapper">
                                                                        <h2>What <span>Our Client</span> Say's</h2>
                                                                        <p>Serving our clients with top-notch results is
                                                                            our major priority, htmlFor each expert at
                                                                            NavUday Solution Digital, our motive is to build a
                                                                            strong relationship with our client htmlFor
                                                                            equivalent growth and success.
                                                                        </p>

                                                                    </div>
                                                                </div>

                                                                <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                                                    <div className="wpb_wrapper">
                                                                        <div className="white" style={{ background: "rgba(0,0,0,0)", border: "solid 0px rgba(0,0,0,0)", borderRadius: 0, padding: "0px 0px 0px 0px" }}>
                                                                            <div id="slider_115" className="owl-carousel sa_owl_theme autohide-arrows owl-loaded owl-drag" data-slider-id="slider_115" style={{ visibility: "visible" }}>




                                                                                <div className="owl-stage-outer">
                                                                                    <div className="owl-stage" style={{ transform: "translate3d(-1300px, 0px, 0px)", transition: "0.2s", width: 2080 }}>
                                                                                        <div className="owl-item cloned" style={{ width: 260 }}>
                                                                                            <div id="slider_115_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                <div className="review-bx">
                                                                                                    <div className="qutes-tst">
                                                                                                         </div>
                                                                                                    <div className="tstpara">
                                                                                                        <p>Adsdad
                                                                                                            Digital's
                                                                                                            website
                                                                                                            design
                                                                                                            services are
                                                                                                            top-notch.
                                                                                                            Their team
                                                                                                            is
                                                                                                            professional,
                                                                                                            talented and
                                                                                                            very
                                                                                                            responsive.
                                                                                                            They created
                                                                                                            a beautiful
                                                                                                            website that
                                                                                                            perfectly
                                                                                                            represents
                                                                                                            our brand
                                                                                                            and
                                                                                                            effectively
                                                                                                            communicates
                                                                                                            our message
                                                                                                            to our
                                                                                                            target
                                                                                                            audience. We
                                                                                                            highly
                                                                                                            recommend
                                                                                                            them!</p>
                                                                                                    </div>
                                                                                                    <div className="strr">
                                                                                                        Shubham Chabbra
                                                                                                        (Sketra)<i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star-half-alt" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                        <div className="owl-item" style={{ width: 260 }}>
                                                                                            <div id="slider_115_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                <div className="review-bx">
                                                                                                    <div className="qutes-tst">
                                                                                                         </div>
                                                                                                    <div className="tstpara">
                                                                                                        <p>They have
                                                                                                            been a game
                                                                                                            changer htmlFor
                                                                                                            our cleaning
                                                                                                            services
                                                                                                            business.
                                                                                                            Their team
                                                                                                            has helped
                                                                                                            us generate
                                                                                                            qualified
                                                                                                            leads
                                                                                                            through
                                                                                                            targeted
                                                                                                            campaigns.
                                                                                                            Their
                                                                                                            professionalism
                                                                                                            and
                                                                                                            expertise in
                                                                                                            lead
                                                                                                            generation
                                                                                                            have been
                                                                                                            invaluable
                                                                                                            to our
                                                                                                            business. We
                                                                                                            highly
                                                                                                            recommend
                                                                                                            them!"</p>
                                                                                                    </div>
                                                                                                    <div className="strr">
                                                                                                        Alice
                                                                                                        Allen(Dirt2Tidy)<i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star-half-alt" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item" style={{ width: 260 }}>
                                                                                            <div id="slider_115_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                <div className="review-bx">
                                                                                                    <div className="qutes-tst">
                                                                                                         </div>
                                                                                                    <div className="tstpara">
                                                                                                        <p>We are
                                                                                                            extremely
                                                                                                            satisfied
                                                                                                            with the
                                                                                                            results we
                                                                                                            have seen
                                                                                                            from Adsdad
                                                                                                            Digital's
                                                                                                            SEO
                                                                                                            services.
                                                                                                            They have
                                                                                                            helped us
                                                                                                            increase our
                                                                                                            online
                                                                                                            visibility
                                                                                                            and drive
                                                                                                            more traffic
                                                                                                            to our
                                                                                                            website.
                                                                                                            Their team
                                                                                                            is easy to
                                                                                                            work with
                                                                                                            and always
                                                                                                            goes above
                                                                                                            and beyond
                                                                                                            to exceed
                                                                                                            our
                                                                                                            expectations
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="strr">
                                                                                                        Juka (Juka
                                                                                                        Organics)<i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star-half-alt" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item" style={{ width: 260 }}>
                                                                                            <div id="slider_115_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                <div className="review-bx">
                                                                                                    <div className="qutes-tst">
                                                                                                         </div>
                                                                                                    <div className="tstpara">
                                                                                                        <p>Adsdad
                                                                                                            Digital's
                                                                                                            website
                                                                                                            design
                                                                                                            services are
                                                                                                            top-notch.
                                                                                                            Their team
                                                                                                            is
                                                                                                            professional,
                                                                                                            talented and
                                                                                                            very
                                                                                                            responsive.
                                                                                                            They created
                                                                                                            a beautiful
                                                                                                            website that
                                                                                                            perfectly
                                                                                                            represents
                                                                                                            our brand
                                                                                                            and
                                                                                                            effectively
                                                                                                            communicates
                                                                                                            our message
                                                                                                            to our
                                                                                                            target
                                                                                                            audience. We
                                                                                                            highly
                                                                                                            recommend
                                                                                                            them!</p>
                                                                                                    </div>
                                                                                                    <div className="strr">
                                                                                                        Shubham Chabbra
                                                                                                        (Sketra)<i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star-half-alt" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item active" style={{ width: 260 }}>
                                                                                            <div id="slider_115_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                <div className="review-bx">
                                                                                                    <div className="qutes-tst">
                                                                                                         </div>
                                                                                                    <div className="tstpara">
                                                                                                        <p>Adsdad
                                                                                                            Experts
                                                                                                            helped us
                                                                                                            take our
                                                                                                            home decor
                                                                                                            marketplace
                                                                                                            to the next
                                                                                                            level. Their
                                                                                                            team has
                                                                                                            been
                                                                                                            instrumental
                                                                                                            in improving
                                                                                                            our search
                                                                                                            engine
                                                                                                            rankings,
                                                                                                            increasing
                                                                                                            our organic
                                                                                                            traffic and
                                                                                                            driving more
                                                                                                            sales. Their
                                                                                                            professionalism,
                                                                                                            support and
                                                                                                            expertise
                                                                                                            have been a
                                                                                                            great asset
                                                                                                            to our
                                                                                                            business. We
                                                                                                            highly
                                                                                                            recommend
                                                                                                            them!"</p>
                                                                                                    </div>
                                                                                                    <div className="strr">
                                                                                                        Manjula (Good
                                                                                                        Homes Company)<i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star-half-alt" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item cloned" style={{ width: 260 }}>
                                                                                            <div id="slider_115_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                <div className="review-bx">
                                                                                                    <div className="qutes-tst">
                                                                                                         </div>
                                                                                                    <div className="tstpara">
                                                                                                        <p>They have
                                                                                                            been a game
                                                                                                            changer htmlFor
                                                                                                            our cleaning
                                                                                                            services
                                                                                                            business.
                                                                                                            Their team
                                                                                                            has helped
                                                                                                            us generate
                                                                                                            qualified
                                                                                                            leads
                                                                                                            through
                                                                                                            targeted
                                                                                                            campaigns.
                                                                                                            Their
                                                                                                            professionalism
                                                                                                            and
                                                                                                            expertise in
                                                                                                            lead
                                                                                                            generation
                                                                                                            have been
                                                                                                            invaluable
                                                                                                            to our
                                                                                                            business. We
                                                                                                            highly
                                                                                                            recommend
                                                                                                            them!"</p>
                                                                                                    </div>
                                                                                                    <div className="strr">
                                                                                                        Alice
                                                                                                        Allen(Dirt2Tidy)<i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star-half-alt" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="owl-item cloned" style={{ width: 260 }}>
                                                                                            <div id="slider_115_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 2%" }}>
                                                                                                <div className="review-bx">
                                                                                                    <div className="qutes-tst">
                                                                                                         </div>
                                                                                                    <div className="tstpara">
                                                                                                        <p>We are
                                                                                                            extremely
                                                                                                            satisfied
                                                                                                            with the
                                                                                                            results we
                                                                                                            have seen
                                                                                                            from Adsdad
                                                                                                            Digital's
                                                                                                            SEO
                                                                                                            services.
                                                                                                            They have
                                                                                                            helped us
                                                                                                            increase our
                                                                                                            online
                                                                                                            visibility
                                                                                                            and drive
                                                                                                            more traffic
                                                                                                            to our
                                                                                                            website.
                                                                                                            Their team
                                                                                                            is easy to
                                                                                                            work with
                                                                                                            and always
                                                                                                            goes above
                                                                                                            and beyond
                                                                                                            to exceed
                                                                                                            our
                                                                                                            expectations
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="strr">
                                                                                                        Juka (Juka
                                                                                                        Organics)<i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star" />
                                                                                                        <i className="fas fa-star-half-alt" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="owl-nav"><button type="button" role="presentation" className="owl-prev">Previous</button><button type="button" role="presentation" className="owl-next">Next</button>
                                                                                </div>
                                                                                <div className="owl-dots disabled" />
                                                                                <div className="owl-thumbs" />
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row-full-width vc_clearfix" />
                            </article>
                        </div>{ /* .entry-content */ }

                        { /* #post-## */ }

                    </section>
                </div>{ /* #main */ }
                { /* #primary */ }

            </div>{ /* .row */ }
        </div>





    
    
    
    
    
    
    
    </>
    
    
    
    
    
 
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default Home
