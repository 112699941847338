import React from 'react'

function Contactus() {
  return (
<>

<style type="text/css" data-type="vc_shortcodes-custom-css">{`
        .vc_custom_1673117949121 {
            background-image: url(https://adsdad.com/wp-content/uploads/2022/12/ppcbg-new.jpg?id=171) !important;
        }
    `}</style>



     <div id="content" className="site-content">
            <div className="container">
                <div className="row">

                    <section id="primary" className="content-area col-sm-12">
                        <div id="main" className="site-main" role="main">


                            <article id="post-278" className="post-278 page type-page status-publish hentry">

                                <div className="entry-content">
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-contact-head vc_custom_1673117949121 vc_row-has-fill vc_row-no-padding" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-8">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html contact-head">
                                                                        <div className="wpb_wrapper">
                                                                            <h2>LET’S CONNECT TO <span>NavUday Solution</span></h2>
                                                                            <p>Let’s get connected with the
                                                                                opportunities and make your dream a
                                                                                reality and let us serve it to you with
                                                                                our best compliments.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid bgblue" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth areacontact">
                                                        <div className="ipad ornd wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpcf7 js" id="wpcf7-f283-p278-o3" lang="en-US" dir="ltr">
                                                                        <div className="screen-reader-response">
                                                                            <p role="status" aria-live="polite" aria-atomic="true" />
                                                                            <ul />
                                                                        </div>
                                                                        <form action="/contact-us/#wpcf7-f283-p278-o3" method="post" className="wpcf7-form init" aria-label="Contact form" noValidate data-status="init">
                                                                            <div style={{ display: "none" }}>
                                                                                <input type="hidden" name="_wpcf7" value="283" />
                                                                                <input type="hidden" name="_wpcf7_version" value="5.9.8" />
                                                                                <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                                                                <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f283-p278-o3" />
                                                                                <input type="hidden" name="_wpcf7_container_post" value="278" />
                                                                                <input type="hidden" name="_wpcf7_posted_data_hash"  />
                                                                            </div>
                                                                            <div className="contformarea">
                                                                                <div className="cont-hed">
                                                                                    <h5>Don't Be a Stranger
                                                                                    </h5>
                                                                                    <p>Just Say Hello
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p><span className="wpcf7-form-control-wrap" data-name="text-674"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" placeholder="Your Name"  type="text" name="text-674" fdprocessedid="800sml" /></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p><span className="wpcf7-form-control-wrap" data-name="email-500"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control" aria-required="true" aria-invalid="false" placeholder="Your Email Address"  type="email" name="email-500" fdprocessedid="wq1nhu" /></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p><span className="wpcf7-form-control-wrap" data-name="tel-32"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control" aria-required="true" aria-invalid="false" placeholder="Your Phone Number"  type="tel" name="tel-32" fdprocessedid="v4klib" /></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p><span className="wpcf7-form-control-wrap" data-name="textarea-765"><textarea cols={40} rows={10} maxLength={2000} className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" placeholder="Your Message" name="textarea-765" /></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p><input className="wpcf7-form-control wpcf7-submit has-spinner contbutt btn btn-primary" type="submit" value="Submit" fdprocessedid="vc1xj5" /><span className="wpcf7-spinner" />
                                                                                    </p>
                                                                                </div>
                                                                            </div><input type="hidden" className="wpcf7-pum"  />
                                                                            <div className="wpcf7-response-output" aria-hidden="true" />
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-8">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html cer-partner">
                                                                        <div className="wpb_wrapper">
                                                                            <span>NavUday Solution</span>
                                                                            <h2>ARE CERTIFIED PARTNER WITH</h2>
                                                                            <div className="white" style={{ background: "rgba(0,0,0,0)", border: "solid 0px rgba(0,0,0,0)", borderRadius: 0, padding: "0px 0px 0px 0px" }}>
                                                                                <div id="slider_288" className="owl-carousel sa_owl_theme autohide-arrows owl-loaded owl-drag" data-slider-id="slider_288" style={{ visibility: "visible" }}>




                                                                                    <div className="owl-stage-outer">
                                                                                        <div className="owl-stage" style={{ transform: "translate3d(-1060px, 0px, 0px)", transition: "0.2s", width: 2828 }}>
                                                                                            <div className="owl-item cloned" style={{ width: "353.4px" }}>
                                                                                                <div id="slider_288_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 0%" }}>
                                                                                                    <div className="part">
                                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                                decoding="async"
                                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"/></noscript>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: "353.4px" }}>
                                                                                                <div id="slider_288_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 0%" }}>
                                                                                                    <div className="part">
                                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                                decoding="async"
                                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"/></noscript>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: "353.4px" }}>
                                                                                                <div id="slider_288_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 0%" }}>
                                                                                                    <div className="part">
                                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                                decoding="async"
                                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"/></noscript>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item active" style={{ width: "353.4px" }}>
                                                                                                <div id="slider_288_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 0%" }}>
                                                                                                    <div className="part">
                                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                                decoding="async"
                                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"/></noscript>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: "353.4px" }}>
                                                                                                <div id="slider_288_slide03" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 0%" }}>
                                                                                                    <div className="part">
                                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                                decoding="async"
                                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"/></noscript>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item" style={{ width: "353.4px" }}>
                                                                                                <div id="slider_288_slide04" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 0%" }}>
                                                                                                    <div className="part">
                                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                                decoding="async"
                                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"/></noscript>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: "353.4px" }}>
                                                                                                <div id="slider_288_slide01" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 0%" }}>
                                                                                                    <div className="part">
                                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                                decoding="async"
                                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"/></noscript>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="owl-item cloned" style={{ width: "353.4px" }}>
                                                                                                <div id="slider_288_slide02" className="sa_hover_container" style={{ padding: "0% 0%", margin: "0px 0%" }}>
                                                                                                    <div className="part">
                                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                                                                                                decoding="async"
                                                                                                                src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"/></noscript>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="owl-nav"><button type="button" role="presentation" className="owl-prev">Previous</button><button type="button" role="presentation" className="owl-next">Next</button>
                                                                                    </div>
                                                                                    <div className="owl-dots disabled">
                                                                                    </div>
                                                                                    <div className="owl-thumbs" />
                                                                                </div>
                                                                            </div>
                                                                         

                                                                        </div>
                                                                    </div>

                                                                    <div className="wpb_text_column wpb_content_element  contact-mapp">
                                                                        <div className="wpb_wrapper">
                                                                            <p><iframe style={{ border: "0" }} data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448196.0531265197!2d76.81304008661843!3d28.643795376980222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1673121822897!5m2!1sen!2sin" width="100%" height="450" allowFullScreen src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448196.0531265197!2d76.81304008661843!3d28.643795376980222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1673121822897!5m2!1sen!2sin" className=" lazyloaded" data-load-mode="1" /></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div className="vc_row wpb_row vc_row-fluid padding-bottom-contact">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid top-contact">
                                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="locaion"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/call-foot.png" src="https://adsdad.com/wp-content/uploads/2023/01/call-foot.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 72, SmushPlaceholderAspectRatio: "72/78" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/call-foot.png" /></noscript><br />
                                                                                <a href="tel:+919999999999">+91
                                                                                    9999999999</a>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="locaion"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/email-foot.png" src="https://adsdad.com/wp-content/uploads/2023/01/email-foot.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 72, SmushPlaceholderAspectRatio: "72/78" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/email-foot.png" /></noscript><br />
                                                                                <a href="mailto:contact@navudaysolution.com">contact@navudaysutin.com</a>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element ">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="locaion"><img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/location-foot.png" src="https://adsdad.com/wp-content/uploads/2023/01/location-foot.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 72, SmushPlaceholderAspectRatio: "72/78" }} /><noscript><img
                                                                                        decoding="async"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/location-foot.png" /></noscript><br />
                                                                                <a href="" target="_blank" rel="noopener"> Company Address</a>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid social-padding">
                                                        <div className="wpb_column vc_column_container vc_col-sm-1/5">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html social-facebook">
                                                                        <div className="wpb_wrapper">
                                                                            <a href="#" target="_blank" rel="noopener nofollow"><i className="fa fa-facebook-official" aria-hidden="true" />FACEBOOK</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-1/5">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html social-skype">
                                                                        <div className="wpb_wrapper">
                                                                            <a href="#" target="_blank" rel="noopener nofollow"><i className="fab fa-skype" />SKYPE</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-1/5">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html social-linkedin">
                                                                        <div className="wpb_wrapper">
                                                                            <a href="#" target="_blank" rel="noopener nofollow"><i className="fab fa-linkedin-in" />lINKEDIN</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-1/5">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html social-instagram">
                                                                        <div className="wpb_wrapper">
                                                                            <a href="#" target="_blank" rel="noopener nofollow"><i className="fab fa-instagram" />INSTAGRAM</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-1/5">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html social-twitter">
                                                                        <div className="wpb_wrapper">
                                                                            <a href="#" target="_blank" rel="noopener nofollow"><i className="fab fa-twitter" />
                                                                                TWITTER</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{ /* .entry-content */ }

                            </article>{ /* #post-## */ }

                        </div>{ /* #main */ }
                    </section>{ /* #primary */ }

                </div>{ /* .row */ }
            </div>{ /* .container */ }
        </div>













</>
  )
}

export default Contactus
