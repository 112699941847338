import React from 'react'

function About() {
  return (
    <>
    <style type="text/css" data-type="vc_shortcodes-custom-css">{`
        .vc_custom_1673117949121 {
            background-image: url(https://adsdad.com/wp-content/uploads/2022/12/ppcbg-new.jpg?id=171) !important;
        }

        .vc_custom_1673889930975 {
            margin-top: 30px !important;
        }

        .vc_custom_1674314775172 {
            margin-top: 50px !important;
            padding-bottom: 50px !important;
        }
    `}</style>
    
        
        <div id="content" className="site-content" >
            <div className="container">
                <div className="row">

                    <section id="primary" className="content-area col-sm-12">
                        <div id="main" className="site-main" role="main">


                            <article id="post-334" className="post-334 page type-page status-publish hentry">

                                <div className="entry-content">
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid padding-contact-head vc_custom_1673117949121 vc_row-has-fill vc_row-no-padding" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-8">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html contact-head">
                                                                        <div className="wpb_wrapper">
                                                                            <h2>WHO ARE <span>NavUday Solution Digital</span></h2>
                                                                            <p>NavUday Solution Digital has been working with a
                                                                                team of talented and creative experts to
                                                                                solve your online problems htmlFor a decade.
                                                                            </p>
                                                                            <a href="/contact" className="oursservlink"><i className="fas fa-briefcase" /> Your
                                                                                One-Stop Solution</a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp wpb_start_animation animated">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img fetchpriority="high" decoding="async" width="300" height="214" src="https://adsdad.com/wp-content/uploads/2023/01/abs-300x214.png" className="vc_single_image-img attachment-medium" alt="" title="abs" srcSet="https://adsdad.com/wp-content/uploads/2023/01/abs-300x214.png 300w, https://adsdad.com/wp-content/uploads/2023/01/abs.png 413w" sizes="(max-width: 300px) 100vw, 300px" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid ab-us" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth ab-cen">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  abs-addad">
                                                                        <div className="wpb_wrapper">
                                                                            <h2>Give Your Business New Heights of
                                                                                Success<br />
                                                                                With <span className="">NavUday Solution Digital
                                                                                </span>Experts</h2>
                                                                            <p>NavUday Solution Digital is a leading digital
                                                                                marketing agency in Delhi that was
                                                                                founded in 2020 with the sole purpose of
                                                                                assisting every business in establishing
                                                                                an online presence. This aids them in
                                                                                reaching the objective they have set htmlFor
                                                                                their company and enables them to see a
                                                                                more promising future. We have put much
                                                                                effort into earning the title of top
                                                                                digital marketing agency. We got to
                                                                                where we are today by working
                                                                                diligently, firmly, and passionately
                                                                                toward our client’s objectives. We also
                                                                                assisted them in achieving their goals.
                                                                            </p>
                                                                            <p>We offer a vision htmlFor those who do not
                                                                                know or comprehend the value of being
                                                                                online. We explained to them the
                                                                                advantages of having a website and how
                                                                                each click could alter your company’s
                                                                                position. To prevent any problems
                                                                                between the client and us, we
                                                                                collaborate methodically. Accordingly,
                                                                                we develop, design, and market your
                                                                                website. We’ll start by creating your
                                                                                idea htmlFor your internet business and
                                                                                vision. Once we are aware, we begin
                                                                                developing and launching. Finally, we
                                                                                market. Through digital marketing, you
                                                                                may spread the word about your company
                                                                                and learn how to reach out to potential
                                                                                customers in a specific age range.</p>
                                                                            <p>Website design and digital marketing
                                                                                agency NavUday Solution Digital was founded to
                                                                                serve clients how they see fit. Our
                                                                                capable team’s dedication helps us
                                                                                accomplish our objectives and enables us
                                                                                to make promises to our customers that
                                                                                we can keep. We have professionals to
                                                                                provide your website with high-quality,
                                                                                cost-effective internet marketing
                                                                                services.</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div className="vc_row wpb_row vc_row-fluid">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_empty_space" style={{ height: 50 }}><span className="vc_empty_space_inner" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp boxraea wpb_start_animation animated">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img decoding="async" width="1360" height="619" data-src="https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg.webp" className="vc_single_image-img attachment-full lazyloaded" alt="" title="aboutmiddlebanner.jpg" data-srcset="https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg.webp 1360w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-300x137.webp 300w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-1024x466.webp 1024w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-768x350.webp 768w" data-sizes="(max-width: 1360px) 100vw, 1360px" src="https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg.webp" style={{ SmushPlaceholderWidth: 1360, SmushPlaceholderAspectRatio: "1360/619" }} sizes="(max-width: 1360px) 100vw, 1360px" srcSet="https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg.webp 1360w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-300x137.webp 300w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-1024x466.webp 1024w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-768x350.webp 768w" /><noscript><img
                                                                                        decoding="async" width="1360"
                                                                                        height="619"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg.webp"
                                                                                        className="vc_single_image-img attachment-full"
                                                                                        alt=""
                                                                                        title="aboutmiddlebanner.jpg"
                                                                                        srcSet="https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg.webp 1360w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-300x137.webp 300w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-1024x466.webp 1024w, https://adsdad.com/wp-content/uploads/2023/01/aboutmiddlebanner.jpg-768x350.webp 768w"
                                                                                        sizes="(max-width: 1360px) 100vw, 1360px" /></noscript>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  paraab">
                                                                        <div className="wpb_wrapper">
                                                                            <p>To provide high-quality, professional,
                                                                                and reasonably priced <a href="https://en.wikipedia.org/wiki/Digital_marketing">internet
                                                                                    marketing</a> services that our
                                                                                professionals create to produce
                                                                                favorable, quick, and sales-driven
                                                                                outcomes, NavUday Solution Digital must have the
                                                                                appropriate knowledge and abilities. We
                                                                                create top-notch, aesthetically
                                                                                pleasing, and beneficial websites
                                                                                designed to enhance your professional
                                                                                image and effectively advertise your
                                                                                goods, services, and expertise to new
                                                                                and potential customers.</p>
                                                                            <p><a className="all-btn" href="/"><i className="fas fa-hand-peace" aria-hidden="true" />CLICK
                                                                                    HERE TO KNOW MORE ABOUT OUR
                                                                                    PASSIONS</a></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html border-radi">
                                                                        <div className="wpb_wrapper">
                                                                            <div className="heading-area">
                                                                                <h3><span className="">OUR PASSION
                                                                                    </span>TOWARDS WORK</h3>
                                                                                <p>Result Driven Best in Class Digital
                                                                                    Marketing Services</p>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="bo">
                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/innergdicon.png" src="https://adsdad.com/wp-content/uploads/2023/01/innergdicon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 49, SmushPlaceholderAspectRatio: "49/49" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2023/01/innergdicon.png"/></noscript>
                                                                                        <span>GRAPHIC DESIGN</span>
                                                                                    </div>

                                                                                    <div className="bo">
                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/innerwebdsnicon.png" src="https://adsdad.com/wp-content/uploads/2023/01/innerwebdsnicon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 49, SmushPlaceholderAspectRatio: "49/49" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2023/01/innerwebdsnicon.png"/></noscript>
                                                                                        <span>WEB DEVELOPMENT</span>
                                                                                    </div>

                                                                                    <div className="bo">
                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/innerseoicon.png" src="https://adsdad.com/wp-content/uploads/2023/01/innerseoicon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 49, SmushPlaceholderAspectRatio: "49/49" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2023/01/innerseoicon.png"/></noscript>
                                                                                        <span>SEO</span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-6">
                                                                                    <div className="bo">
                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/innerwebdevicon.png" src="https://adsdad.com/wp-content/uploads/2023/01/innerwebdevicon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 49, SmushPlaceholderAspectRatio: "49/49" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2023/01/innerwebdevicon.png"/></noscript>
                                                                                        <span>WEBSITE DESIGN</span>
                                                                                    </div>
                                                                                    <div className="bo">
                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/innerecmmrceicon.png" src="https://adsdad.com/wp-content/uploads/2023/01/innerecmmrceicon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 49, SmushPlaceholderAspectRatio: "49/49" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2023/01/innerecmmrceicon.png"/></noscript>
                                                                                        <span>E-COMMERCE</span>
                                                                                    </div>

                                                                                    <div className="bo">
                                                                                        <img decoding="async" data-src="https://adsdad.com/wp-content/uploads/2023/01/innersmoicon.png" src="https://adsdad.com/wp-content/uploads/2023/01/innersmoicon.png" className=" lazyloaded" style={{ SmushPlaceholderWidth: 49, SmushPlaceholderAspectRatio: "49/49" }} /><noscript><img
                                                                                                decoding="async"
                                                                                                src="https://adsdad.com/wp-content/uploads/2023/01/innersmoicon.png"/></noscript>
                                                                                        <span>SMO</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                    <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true" className="vc_row wpb_row vc_row-fluid" style={{ position: "relative", left: 0, boxSizing: "border-box", width: 415, maxWidth: 415 }}>
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth vc_custom_1673889930975">
                                                        <div className="ipad miss wpb_column vc_column_container vc_col-sm-4">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper" />
                                                            </div>
                                                        </div>
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-8">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  missionpara">
                                                                        <div className="wpb_wrapper">
                                                                            <p><span data-preserver-spaces="true">Our
                                                                                    sole goal is to inform individuals
                                                                                    about the advantages of doing
                                                                                    business online and how our
                                                                                    organization can assist you. Our
                                                                                    company has gotten to where it is
                                                                                    now thanks to the results of our
                                                                                    efhtmlFort and our love for what we do.
                                                                                    We were successful because of our
                                                                                    work ethic, client-handling
                                                                                    strategies, and teamwork.</span></p>
                                                                            <p><span data-preserver-spaces="true">We
                                                                                    want people and businesses, no
                                                                                    matter how big or little, to profit
                                                                                    from internet media.</span></p>
                                                                            <ul>
                                                                                <li><span data-preserver-spaces="true">By
                                                                                        being online, they reach a
                                                                                        larger audience.</span></li>
                                                                                <li><span data-preserver-spaces="true">We
                                                                                        enable them to improve their
                                                                                        marketing and generate more
                                                                                        revenue through digital
                                                                                        marketing.</span></li>
                                                                                <li><span data-preserver-spaces="true">To
                                                                                        subtly and distinctively
                                                                                        showcase their brand with
                                                                                        best-in-class web design and
                                                                                        digital marketing.</span></li>
                                                                                <li><span data-preserver-spaces="true">We
                                                                                        offer our clients options that
                                                                                        satisfy our promises and care
                                                                                        htmlFor their problems.</span></li>
                                                                                <li><span data-preserver-spaces="true">Our
                                                                                        objective is to realize
                                                                                        aspirations using cutting-edge
                                                                                        technology, creative ideas, and
                                                                                        visualization.</span></li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth vc_custom_1674314775172">
                                                        <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element  missionpara visonpara">
                                                                        <div className="wpb_wrapper">
                                                                            <h2>OUR <span className="">VISION</span></h2>
                                                                            <p>TO BE THE WORLD’S MOST TRUSTED DIGITAL
                                                                                MARKETING COMPANY. FOR US, RESPECT IS
                                                                                EQUIVALENT TO QUALITY, COMMITMENT, AND
                                                                                CUSTOMER-CENTRICITY.</p>
                                                                            <p><span data-preserver-spaces="true">The
                                                                                    most reputable business, NavUday Solution
                                                                                    Digital, is renowned htmlFor producing
                                                                                    high-quality work, showing
                                                                                    dedication to its customers, keeping
                                                                                    its word, and meeting their needs.
                                                                                    We are a reputable business that
                                                                                    offers knowledge and
                                                                                    experience.</span></p>
                                                                            <p> </p>
                                                                            <ul>
                                                                                <li><span data-preserver-spaces="true">We
                                                                                        wish to establish ourselves as a
                                                                                        reputable business with a solid
                                                                                        reputation for the high caliber
                                                                                        of our services.</span></li>
                                                                                <li><span data-preserver-spaces="true">When
                                                                                        a client comes to us with their
                                                                                        business, we should give them
                                                                                        trust, excellent work, timely
                                                                                        project delivery, and a sense of
                                                                                        pride in the work we both
                                                                                        did.</span></li>
                                                                                <li><span data-preserver-spaces="true">Our
                                                                                        goal is to provide best-in-class
                                                                                        digital marketing services to
                                                                                        our clients. </span></li>
                                                                                <li><span data-preserver-spaces="true">We
                                                                                        aspire to be the business that
                                                                                        does its job the best, is
                                                                                        trusted htmlFor its work, and offers
                                                                                        the most excellent media
                                                                                        strategies.</span><i className="fas fa-arrow-alt-right" />
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ipad vf wpb_column vc_column_container vc_col-sm-6">
                                                            <div className="vc_column-inner">
                                                                <div className="wpb_wrapper">
                                                                    <div className="wpb_single_image wpb_content_element vc_align_center">

                                                                        <figure className="wpb_wrapper vc_figure">
                                                                            <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img decoding="async" width="1060" height="706" data-src="https://adsdad.com/wp-content/uploads/2023/01/vision-img.jpg" className="vc_single_image-img attachment-full lazyloaded" alt="" title="vision-img" data-srcset="https://adsdad.com/wp-content/uploads/2023/01/vision-img.jpg 1060w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-768x512.jpg 768w" data-sizes="(max-width: 1060px) 100vw, 1060px" src="https://adsdad.com/wp-content/uploads/2023/01/vision-img.jpg" style={{ SmushPlaceholderWidth: 1060, SmushPlaceholderAspectRatio: "1060/706" }} sizes="(max-width: 1060px) 100vw, 1060px" srcSet="https://adsdad.com/wp-content/uploads/2023/01/vision-img.jpg 1060w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-768x512.jpg 768w" /><noscript><img
                                                                                        decoding="async" width="1060"
                                                                                        height="706"
                                                                                        src="https://adsdad.com/wp-content/uploads/2023/01/vision-img.jpg"
                                                                                        className="vc_single_image-img attachment-full"
                                                                                        alt="" title="vision-img"
                                                                                        srcSet="https://adsdad.com/wp-content/uploads/2023/01/vision-img.jpg 1060w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-300x200.jpg 300w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-1024x682.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/01/vision-img-768x512.jpg 768w"
                                                                                        sizes="(max-width: 1060px) 100vw, 1060px" /></noscript>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vc_row-full-width vc_clearfix" />
                                </div>{ /* .entry-content */ }

                            </article>{ /* #post-## */ }

                        </div>{ /* #main */ }
                    </section>{ /* #primary */ }

                </div>{ /* .row */ }
            </div>{ /* .container */ }
        </div>

    
    
    
    
    
    
    </>
  )
}

export default About
