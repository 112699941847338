import React from "react";

function Googleads() {
  return (
    <>

<style type="text/css" data-type="vc_shortcodes-custom-css">{`.vc_custom_1672420766299{background-image: url(https://adsdad.com/wp-content/uploads/2022/12/ppcbg-new.jpg?id=171) !important;}`}</style>



      <div id="content" className="site-content">
        <div className="container">
          <div className="row">
            <section id="primary" className="content-area col-sm-12">
              <div id="main" className="site-main" role="main">
                <article
                  id="post-887"
                  className="post-887 page type-page status-publish hentry"
                >
                  <div className="entry-content">
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-ppc vc_custom_1672420766299 vc_row-has-fill vc_row-no-padding"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-8">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html ppc-area-hd">
                                      <div className="wpb_wrapper">
                                        <h1>Leading Google Ads Agency</h1>
                                        <h2>
                                          BE THERE, WHEN PEOPLE ARE SEARCHING
                                          FOR WHAT YOU OFFER
                                        </h2>
                                        <p>
                                          We boost your Google Ads Return on
                                          Investment (ROI) by in-depth ad
                                          campaign optimization and find those
                                          customers who are already searching
                                          for your product or services – Get
                                          timely and transparent campaign
                                          reports!
                                        </p>
                                        <div className="ftp-top">
                                          <ul className="crtified">
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"
                                                />
                                              </noscript>{" "}
                                            </li>
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"
                                                />
                                              </noscript>{" "}
                                            </li>
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"
                                                />
                                              </noscript>{" "}
                                            </li>
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"
                                                />
                                              </noscript>{" "}
                                            </li>
                                            <li>
                                              <img
                                                decoding="async"
                                                data-src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"
                                                className=" lazyloaded"
                                                style={{
                                                  SmushPlaceholderWidth: 222,
                                                  SmushPlaceholderAspectRatio:
                                                    "222/81",
                                                }}
                                              />
                                              <noscript>
                                                <img
                                                  decoding="async"
                                                  src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"
                                                />
                                              </noscript>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div
                                      className="wpcf7 js"
                                      id="wpcf7-f977-p887-o3"
                                      lang="en-US"
                                      dir="ltr"
                                    >
                                      <div className="screen-reader-response">
                                        <p
                                          role="status"
                                          aria-live="polite"
                                          aria-atomic="true"
                                        />{" "}
                                        <ul />
                                      </div>
                                      <form
                                        action="/google-ads-agency-delhi/#wpcf7-f977-p887-o3"
                                        method="post"
                                        className="wpcf7-form init"
                                        aria-label="Contact form"
                                        noValidate
                                        data-status="init"
                                      >
                                        <div style={{ display: "none" }}>
                                          <input
                                            type="hidden"
                                            name="_wpcf7"
                                            value="977"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_version"
                                            value="5.9.8"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_locale"
                                            value="en_US"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_unit_tag"
                                            value="wpcf7-f977-p887-o3"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_container_post"
                                            value="887"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_posted_data_hash"
                                            
                                          />
                                        </div>
                                        <div className="ppcform">
                                          <h5>Grow Your Brand</h5>
                                          <h6>with Google PPC advertising</h6>
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <div className="fld">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="text-659"
                                                  >
                                                    <input
                                                      size={40}
                                                      maxLength={400}
                                                      className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Full Name"
                                                      
                                                      type="text"
                                                      name="text-659"
                                                      fdprocessedid="ljches4d"
                                                    />
                                                  </span>
                                                  <i className="fas fa-user" />
                                                </p>
                                              </div>
                                              <div className="fld">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="email-722"
                                                  >
                                                    <input
                                                      size={40}
                                                      maxLength={400}
                                                      className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Email Address"
                                                      
                                                      type="email"
                                                      name="email-722"
                                                      fdprocessedid="mizcqt"
                                                    />
                                                  </span>
                                                  <i className="fas fa-envelope" />
                                                </p>
                                              </div>
                                              <div className="fld">
                                                <p>
                                                  <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="tel-659"
                                                  >
                                                    <input
                                                      size={40}
                                                      maxLength={400}
                                                      className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control"
                                                      aria-required="true"
                                                      aria-invalid="false"
                                                      placeholder="Phone No"
                                                      
                                                      type="tel"
                                                      name="tel-659"
                                                      fdprocessedid="ax3xf"
                                                    />
                                                  </span>
                                                  <i className="fas fa-phone-alt" />
                                                </p>
                                              </div>
                                              <div className="fld but">
                                                <p>
                                                  <input
                                                    className="wpcf7-form-control wpcf7-submit has-spinner btn btn-primary"
                                                    type="submit"
                                                    value="Get Your Free Proposal"
                                                    fdprocessedid="omd5e"
                                                  />
                                                  <span className="wpcf7-spinner" />
                                                  <i
                                                    className="fa fa-angle-right"
                                                    aria-hidden="true"
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <input
                                          type="hidden"
                                          className="wpcf7-pum"
                                        />
                                        <div
                                          className="wpcf7-response-output"
                                          aria-hidden="true"
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        {/* Widget Shortcode */}
                                        <div
                                          id="custom_html-2"
                                          className="widget_text widget widget_custom_html widget-shortcode area-arbitrary "
                                        >
                                          <div className="textwidget custom-html-widget">
                                            <div className="row">
                                              <div className="col-lg-3">
                                                <div className="box-area">
                                                  <p>We Have Over</p>
                                                  <h5>3+</h5>
                                                  <span>
                                                    Years of Experience
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-lg-3">
                                                <div className="box-area">
                                                  <p>Expertise In</p>
                                                  <h5>12+</h5>
                                                  <span>Technologies</span>
                                                </div>
                                              </div>
                                              <div className="col-lg-3">
                                                <div className="box-area">
                                                  <p>We Have Done</p>
                                                  <h5>2K+</h5>
                                                  <span>
                                                    Projects & Counting
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-lg-3">
                                                <div className="box-area">
                                                  <p>Clients In</p>
                                                  <h5>28+</h5>
                                                  <span>Countries</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* /Widget Shortcode */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-top-bottm"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Beat all the runners-up by hiring the
                                          leading google ads management agency
                                          in Delhi
                                        </h4>
                                        <p>
                                          <span
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >
                                            NavUday SolutionDigital{" "}
                                            <span style={{ fontWeight: "400" }}>
                                              is among the Top 5% of digital
                                              marketing agencies in Delhi. With
                                              perfection and a higher
                                              performance rate with 24*7
                                              customer support, our advertising
                                              experts are doing their best in
                                              the campaign and the digital
                                              world; our experts track each
                                              click to bring out the authority
                                              to our clients.{" "}
                                            </span>
                                          </span>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <ul className="prtner">
                                          <li>
                                            <img
                                              decoding="async"
                                              data-src="https://adsdad.com/wp-content/uploads/2024/05/PremierBadge.png"
                                              width="106"
                                              height="102"
                                              src="https://adsdad.com/wp-content/uploads/2024/05/PremierBadge.png"
                                              className=" lazyloaded"
                                              style={{
                                                SmushPlaceholderWidth: 106,
                                                SmushPlaceholderAspectRatio:
                                                  "106/102",
                                              }}
                                            />
                                            <noscript>
                                              <img
                                                decoding="async"
                                                class=""
                                                src="https://adsdad.com/wp-content/uploads/2024/05/PremierBadge.png"
                                                width="106"
                                                height="102"
                                              />
                                            </noscript>
                                          </li>
                                          <li>
                                            <img
                                              decoding="async"
                                              data-src="https://adsdad.com/wp-content/uploads/2024/05/ms-partner-2024-sm.webp"
                                              src="https://adsdad.com/wp-content/uploads/2024/05/ms-partner-2024-sm.webp"
                                              className=" lazyloaded"
                                              style={{
                                                SmushPlaceholderWidth: 187,
                                                SmushPlaceholderAspectRatio:
                                                  "187/64",
                                              }}
                                            />
                                            <noscript>
                                              <img
                                                decoding="async"
                                                src="https://adsdad.com/wp-content/uploads/2024/05/ms-partner-2024-sm.webp"
                                              />
                                            </noscript>
                                          </li>
                                          <li>
                                            <img
                                              decoding="async"
                                              data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business.jpg"
                                              src="https://adsdad.com/wp-content/uploads/2022/12/meta-business.jpg"
                                              className=" lazyloaded"
                                              style={{
                                                SmushPlaceholderWidth: 165,
                                                SmushPlaceholderAspectRatio:
                                                  "165/71",
                                              }}
                                            />
                                            <noscript>
                                              <img
                                                decoding="async"
                                                src="https://adsdad.com/wp-content/uploads/2022/12/meta-business.jpg"
                                              />
                                            </noscript>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  top-img">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            fetchpriority="high"
                                            decoding="async"
                                            width="938"
                                            height="630"
                                            src="https://adsdad.com/wp-content/uploads/2023/02/present.jpg"
                                            className="vc_single_image-img attachment-full"
                                            alt=""
                                            title=""
                                            srcSet="https://adsdad.com/wp-content/uploads/2023/02/present.jpg 938w, https://adsdad.com/wp-content/uploads/2023/02/present-300x201.jpg 300w, https://adsdad.com/wp-content/uploads/2023/02/present-768x516.jpg 768w"
                                            sizes="(max-width: 938px) 100vw, 938px"
                                          />
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-50"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  boxraea">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            decoding="async"
                                            width="2560"
                                            height="1440"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-scaled.jpeg"
                                            className="vc_single_image-img attachment-full lazyload"
                                            alt=""
                                            title="FRNXnLpXEAEx2Ok"
                                            data-srcset="https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-scaled.jpeg 2560w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-300x169.jpeg 300w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-1024x576.jpeg 1024w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-768x432.jpeg 768w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-1536x864.jpeg 1536w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-2048x1152.jpeg 2048w"
                                            data-sizes="(max-width: 2560px) 100vw, 2560px"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            style={{
                                              SmushPlaceholderWidth: 2560,
                                              SmushPlaceholderAspectRatio:
                                                "2560/1440",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              width="2560"
                                              height="1440"
                                              src="https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-scaled.jpeg"
                                              class="vc_single_image-img attachment-full"
                                              alt=""
                                              title="FRNXnLpXEAEx2Ok"
                                              srcset="https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-scaled.jpeg 2560w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-300x169.jpeg 300w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-1024x576.jpeg 1024w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-768x432.jpeg 768w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-1536x864.jpeg 1536w, https://adsdad.com/wp-content/uploads/2023/05/FRNXnLpXEAEx2Ok-2048x1152.jpeg 2048w"
                                              sizes="(max-width: 2560px) 100vw, 2560px"
                                            />
                                          </noscript>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Examine your Advertising goals with
                                          impressive and measurable results.
                                        </h4>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Our experts give their best to each
                                            campaign to reach the audience and
                                            turn each lead into ACTION. 
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Our experts examine detailed
                                            reporting to promote transparency
                                            and deep insights with our clients. 
                                          </span>
                                        </p>
                                      </div>
                                    </div>

                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="text-green">
                                          <span>80%</span>{" "}
                                          <p>
                                            of Google Ads ad spend to produce a
                                            single conversion into an authentic
                                            lead.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-50"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidthh rev">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Our certified Google Ads management in
                                          Delhi will help your customers to find
                                          you.
                                        </h4>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            With NavUday Solution, a digital marketing
                                            agency in Delhi, identifying the
                                            correct audience and finding smart
                                            ways to find and connect you!
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Our team has helped hundreds of
                                            businesses to scale up their game in
                                            the digital world.{" "}
                                          </span>
                                        </p>
                                        <div className="btn-rt">
                                          <ul>
                                            <li>
                                              <a href="#">Local PPC</a>
                                            </li>
                                            <li>
                                              <a href="#">National PPC</a>
                                            </li>
                                            <li>
                                              <a href="#">Ecommerce PPC</a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_center  wpb_animate_when_almost_visible wpb_zoomInUp zoomInUp  top-img boxraea">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            loading="lazy"
                                            decoding="async"
                                            width="1024"
                                            height="539"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/05/ppc3-1024x539-1.jpg"
                                            className="vc_single_image-img attachment-full lazyload"
                                            alt=""
                                            title="ppc3-1024x539"
                                            data-srcset="https://adsdad.com/wp-content/uploads/2023/05/ppc3-1024x539-1.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/05/ppc3-1024x539-1-300x158.jpg 300w, https://adsdad.com/wp-content/uploads/2023/05/ppc3-1024x539-1-768x404.jpg 768w"
                                            data-sizes="(max-width: 1024px) 100vw, 1024px"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            style={{
                                              SmushPlaceholderWidth: 1024,
                                              SmushPlaceholderAspectRatio:
                                                "1024/539",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width="1024"
                                              height="539"
                                              src="https://adsdad.com/wp-content/uploads/2023/05/ppc3-1024x539-1.jpg"
                                              class="vc_single_image-img attachment-full"
                                              alt=""
                                              title="ppc3-1024x539"
                                              srcset="https://adsdad.com/wp-content/uploads/2023/05/ppc3-1024x539-1.jpg 1024w, https://adsdad.com/wp-content/uploads/2023/05/ppc3-1024x539-1-300x158.jpg 300w, https://adsdad.com/wp-content/uploads/2023/05/ppc3-1024x539-1-768x404.jpg 768w"
                                              sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                          </noscript>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-50 bgcolor-blue wpb_animate_when_almost_visible wpb_fadeIn fadeIn"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="ipad wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  txtsame">
                                      <div className="wpb_wrapper">
                                        <h3 className="whitehead">
                                          Know You’re Supported
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="boxraea">
                                          <img
                                            decoding="async"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/05/target-customer-1.jpg"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            className="lazyload"
                                            style={{
                                              SmushPlaceholderWidth: 988,
                                              SmushPlaceholderAspectRatio:
                                                "988/512",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              src="https://adsdad.com/wp-content/uploads/2023/05/target-customer-1.jpg"
                                            />
                                          </noscript>
                                          <h5>
                                            Target customers when they're
                                            looking for business like yours
                                          </h5>
                                          <p>
                                            It's high time to create higher
                                            conversations by targeting potential
                                            and correct audiences as they look
                                            for your brand. NavUday Solutiongives the
                                            right vision and way to connect.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="boxraea">
                                          <img
                                            decoding="async"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/05/Anytime-Anywhere-Any-device.jpg"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            className="lazyload"
                                            style={{
                                              SmushPlaceholderWidth: 1226,
                                              SmushPlaceholderAspectRatio:
                                                "1226/644",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              src="https://adsdad.com/wp-content/uploads/2023/05/Anytime-Anywhere-Any-device.jpg"
                                            />
                                          </noscript>
                                          <h5>
                                            Reach your target audience anytime,
                                            anywhere on any device
                                          </h5>
                                          <p>
                                            We ensure that your ads are
                                            displayed to the appropriate
                                            audience, on the correct device, at
                                            the right location, and, most
                                            importantly, at the optimal time
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ipad wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="boxraea">
                                          <img
                                            decoding="async"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/05/Bloggg-1.png"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            className="lazyload"
                                            style={{
                                              SmushPlaceholderWidth: 848,
                                              SmushPlaceholderAspectRatio:
                                                "848/410",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              decoding="async"
                                              src="https://adsdad.com/wp-content/uploads/2023/05/Bloggg-1.png"
                                            />
                                          </noscript>
                                          <h5>
                                            Target audience that give you high
                                            return
                                          </h5>
                                          <p>
                                            Target wherever you want in the
                                            world, any age group, any gender,
                                            and add other demographics which
                                            show your ads to the potential
                                            customers.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-100 wpb_animate_when_almost_visible wpb_fadeIn fadeIn"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  addverting">
                                      <div className="wpb_wrapper">
                                        <h4>Advertise your business on</h4>
                                      </div>
                                    </div>

                                    <div className="wpb_text_column wpb_content_element  addverting">
                                      <div className="wpb_wrapper">
                                        {/* Widget Shortcode */}
                                        <div
                                          id="custom_html-3"
                                          className="widget_text widget widget_custom_html widget-shortcode area-arbitrary "
                                        >
                                          <div className="textwidget custom-html-widget">
                                            <ul className="advertising">
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/reddit-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 162,
                                                      SmushPlaceholderAspectRatio:
                                                        "162/54",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/reddit-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/pinterest-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 169,
                                                      SmushPlaceholderAspectRatio:
                                                        "169/43",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/pinterest-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/linkedin-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 163,
                                                      SmushPlaceholderAspectRatio:
                                                        "163/43",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/linkedin-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/instagram-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 171,
                                                      SmushPlaceholderAspectRatio:
                                                        "171/48",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/instagram-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/facebook-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 172,
                                                      SmushPlaceholderAspectRatio:
                                                        "172/34",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/facebook-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                              <li className="puls">
                                                <a href="#">
                                                  <img
                                                    decoding="async"
                                                    data-src="https://adsdad.com/wp-content/uploads/2023/01/twitter-ads.png"
                                                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                    className="lazyload"
                                                    style={{
                                                      SmushPlaceholderWidth: 181,
                                                      SmushPlaceholderAspectRatio:
                                                        "181/34",
                                                    }}
                                                  />
                                                  <noscript>
                                                    <img
                                                      decoding="async"
                                                      src="https://adsdad.com/wp-content/uploads/2023/01/twitter-ads.png"
                                                    />
                                                  </noscript>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        {/* /Widget Shortcode */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid laptoparea"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-8">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element  headerr">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Let’s start with a free PPC analysis
                                        </h4>
                                      </div>
                                    </div>

                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                      <div className="wpb_wrapper">
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <a href="#">
                                              <div className="whitebxx">
                                                <h4>
                                                  I am new to PPC advertising
                                                </h4>
                                                <p>
                                                  How can pay-per-click help
                                                  grow your business? Let’s run
                                                  the numbers.
                                                </p>
                                              </div>
                                            </a>
                                          </div>
                                          <div className="col-lg-6">
                                            <a href="#">
                                              <div className="whitebxx">
                                                <h4>
                                                  I am running a PPC campaign
                                                </h4>
                                                <p>
                                                  Get a complimentary audit to
                                                  see how much money we can save
                                                  you.
                                                </p>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-4">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid bg-color-grey"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <div className="addverting">
                                          <h4>
                                            Remarkable results for businesses
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <div className="business-portfolio">
                                          <div className="linkareaa">
                                            <a href="#">Our Client Result</a>
                                          </div>
                                          <div className="platform-img">
                                            <img
                                              decoding="async"
                                              data-src="https://adsdad.com/wp-content/uploads/2024/08/Overview-Spark-N-Shine-Google-Ads-1.png"
                                              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                              className="lazyload"
                                              style={{
                                                SmushPlaceholderWidth: 1366,
                                                SmushPlaceholderAspectRatio:
                                                  "1366/649",
                                              }}
                                            />
                                            <noscript>
                                              <img
                                                decoding="async"
                                                src="https://adsdad.com/wp-content/uploads/2024/08/Overview-Spark-N-Shine-Google-Ads-1.png"
                                              />
                                            </noscript>
                                          </div>
                                          <ul className="case-result-list list-unstyled">
                                            <li>
                                              <strong className="font-weight-bold h3">
                                                63%
                                              </strong>
                                              <strong className="d-block font-weight-normal">
                                                Increase in Calls
                                              </strong>
                                            </li>
                                            <li>
                                              <strong className="font-weight-bold h3">
                                                44%
                                              </strong>
                                              <strong className="d-block font-weight-normal">
                                                Conversion Rate
                                              </strong>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                      <div className="wpb_wrapper">
                                        <div className="business-portfolio">
                                          <div className="linkareaa">
                                            <a href="#">Our Client Result</a>
                                          </div>
                                          <div className="platform-img">
                                            <img
                                              decoding="async"
                                              data-src="https://adsdad.com/wp-content/uploads/2024/08/Overview-Psychic-Ramachari-USA-Google-Ads.png"
                                              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                              className="lazyload"
                                              style={{
                                                SmushPlaceholderWidth: 1366,
                                                SmushPlaceholderAspectRatio:
                                                  "1366/592",
                                              }}
                                            />
                                            <noscript>
                                              <img
                                                decoding="async"
                                                src="https://adsdad.com/wp-content/uploads/2024/08/Overview-Psychic-Ramachari-USA-Google-Ads.png"
                                              />
                                            </noscript>
                                          </div>
                                          <ul className="case-result-list list-unstyled">
                                            <li>
                                              <strong className="font-weight-bold h3">
                                                48%
                                              </strong>
                                              <strong className="d-block font-weight-normal">
                                                Increase in Calls
                                              </strong>
                                            </li>
                                            <li>
                                              <strong className="font-weight-bold h3">
                                                38%
                                              </strong>
                                              <strong className="d-block font-weight-normal">
                                                Conversion Rate
                                              </strong>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid padding-70 vc_row-no-padding"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid">
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html footerleft-area">
                                      <div className="wpb_wrapper">
                                        <h4>
                                          Gear up the competition with
                                          effective, efficient, and measurable
                                          Google Ads Marketing.
                                        </h4>
                                        <p>
                                          Place your business as a leader and
                                          expand your market reach with a better
                                          brand image.
                                        </p>
                                        <div className="flex-area">
                                          <div className="bxx">
                                            <span>
                                              - Reach The Right Audience
                                            </span>
                                            <span>
                                              - Faster Result Than SEO
                                            </span>
                                            <span>
                                              - Wide Range Of Targeting
                                            </span>
                                            <span>- Qucik Results</span>
                                            <span>- Maximize ROI</span>
                                          </div>
                                          <div className="bxx">
                                            <span>- Build Brand Awareness</span>
                                            <span>- Measurable Results</span>
                                            <span>- Remarketing</span>
                                            <span>
                                              - Track & Outsmart Your
                                              Competitors’ Ads
                                            </span>
                                            <span>
                                              - Control On Campaign Budget
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="wpb_column vc_column_container vc_col-sm-6">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_single_image wpb_content_element vc_align_right">
                                      <figure className="wpb_wrapper vc_figure">
                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                          <img
                                            loading="lazy"
                                            decoding="async"
                                            width="1030"
                                            height="782"
                                            data-src="https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing.png"
                                            className="vc_single_image-img attachment-full lazyload"
                                            alt=""
                                            title="overview-data-ppc-marketing"
                                            data-srcset="https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing.png 1030w, https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing-300x228.png 300w, https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing-1024x777.png 1024w, https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing-768x583.png 768w"
                                            data-sizes="(max-width: 1030px) 100vw, 1030px"
                                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            style={{
                                              SmushPlaceholderWidth: 1030,
                                              SmushPlaceholderAspectRatio:
                                                "1030/782",
                                            }}
                                          />
                                          <noscript>
                                            <img
                                              loading="lazy"
                                              decoding="async"
                                              width="1030"
                                              height="782"
                                              src="https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing.png"
                                              class="vc_single_image-img attachment-full"
                                              alt=""
                                              title="overview-data-ppc-marketing"
                                              srcset="https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing.png 1030w, https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing-300x228.png 300w, https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing-1024x777.png 1024w, https://adsdad.com/wp-content/uploads/2023/01/overview-data-ppc-marketing-768x583.png 768w"
                                              sizes="(max-width: 1030px) 100vw, 1030px"
                                            />
                                          </noscript>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid faq-bottom-padding"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html title-faq">
                                      <div className="wpb_wrapper">
                                        <h3>
                                          Questions to Ask When Hiring an PPC
                                          Agency
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>What is PPC advertising?</h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            In the pay-per-click (PPC) paradigm
                                            of online advertising, a publisher
                                            is compensated each time an
                                            advertisement link is “clicked” on.
                                            PPC is also known as the
                                            cost-per-click (CPC) strategy.
                                            Search engines like Google and
                                            social media platforms are the main
                                            providers of the pay-per-click
                                            strategy. (e.g., Facebook). The most
                                            used PPC advertising are Google Ads,
                                            Facebook Ads, and Twitter Ads.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>How does PPC advertising work?</h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Advertisers cannot merely pay more
                                            to guarantee that their ads show
                                            more prominently than their rivals
                                            on a search engine’s results page,
                                            also known as a SERP or Search
                                            Engine Results Page. Instead, Google
                                            and other major search engines use
                                            what is known as the “Ad Auction,” a
                                            completely automated process, to
                                            evaluate the relevance and
                                            legitimacy of advertisements that
                                            appear on their SERPs.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          What platforms offer PPC advertising?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            PPC advertisements are placed on
                                            each channel of SERP, and there are
                                            many applications where you can
                                            place your advisement by your
                                            desired audience. 
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            So the leading platforms where you
                                            can use PPC advertising are Google,
                                            Facebook, Pinterest, and Twitter
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          What are the benefits of PPC
                                          advertising?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Here are some of the key advantages
                                            of PPC advertising, and all of them
                                            are great for your business.
                                          </span>
                                        </p>
                                        <ol>
                                          <li
                                            style={{ fontWeight: "400" }}
                                            aria-level="1"
                                          >
                                            <span style={{ fontWeight: "400" }}>
                                              You set the maximum budget for
                                              your ads
                                            </span>
                                          </li>
                                          <li
                                            style={{ fontWeight: "400" }}
                                            aria-level="1"
                                          >
                                            <span style={{ fontWeight: "400" }}>
                                              You only pay when someone clicks
                                              your ad
                                            </span>
                                          </li>
                                          <li
                                            style={{ fontWeight: "400" }}
                                            aria-level="1"
                                          >
                                            <span style={{ fontWeight: "400" }}>
                                              You can compete even if your
                                              search engine rankings aren’t high
                                            </span>
                                          </li>
                                          <li
                                            style={{ fontWeight: "400" }}
                                            aria-level="1"
                                          >
                                            <span style={{ fontWeight: "400" }}>
                                              You can easily test different ads
                                            </span>
                                          </li>
                                          <li
                                            style={{ fontWeight: "400" }}
                                            aria-level="1"
                                          >
                                            <span style={{ fontWeight: "400" }}>
                                              You can gain more brand
                                              recognition
                                            </span>
                                          </li>
                                          <li
                                            style={{ fontWeight: "400" }}
                                            aria-level="1"
                                          >
                                            <span style={{ fontWeight: "400" }}>
                                              You can launch a PPC advertising
                                              campaign in no time
                                            </span>
                                          </li>
                                          <li
                                            style={{ fontWeight: "400" }}
                                            aria-level="1"
                                          >
                                            <span style={{ fontWeight: "400" }}>
                                              You can reach your ideal customers
                                            </span>
                                          </li>
                                        </ol>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          How much do PPC services cost in
                                          India?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Every hit, view, or impression on
                                            your Google ads has a fee. It varies
                                            on the CPC; for example, if the
                                            average CPC for the keywords is 20,
                                            the cost of 10 clicks on Google ads
                                            will be Rs. 200
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            With improved marketing management,
                                            1500 clicks can result in many
                                            customers. For managing Ad
                                            campaigns, NavUday Solutionprovides
                                            reasonable monthly PPC pricing in
                                            India (Google/Facebook Ads
                                            Packages).
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>Why Should I hire PPC Agency?</h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Everyone has experienced the
                                            frustration of wanting to finish
                                            tasks or details but simply lacking
                                            the time.
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            If you employ an internal marketing
                                            team, they must divide their time
                                            between your marketing platforms.
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            It’s not that you shouldn’t perform
                                            campaign audits or test different ad
                                            variations to make your PPC campaign
                                            more effective. You must merely keep
                                            an eye on additional
                                            business-critical tasks.
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Agencies comprehend that. They are
                                            responsible for paying attention to
                                            the minute but crucial PPC
                                            specifics, so you don’t have to.
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            The ability of an agency to
                                            continuously monitor and optimize
                                            your campaign based on performance
                                            data is a significant benefit of
                                            having them solely concentrated on
                                            your advertising accounts. If
                                            immediate outcomes are
                                            disappointing, consult your PPC
                                            expert.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="vc_toggle vc_toggle_default vc_toggle_color_default  vc_toggle_size_md   faq-box">
                                      <div className="vc_toggle_title">
                                        <h4>
                                          Why Choose NavUday SolutionDigital PPC
                                          Services?
                                        </h4>
                                        <i className="vc_toggle_icon" />
                                      </div>
                                      <div className="vc_toggle_content">
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            As you have a crystal clear picture
                                            of your goals, expectations, and
                                            level of participation. So it’s time
                                            to start talking about what
                                            characteristics to look for. 
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Not all PPC agencies are equal. Some
                                            excel at specific advertising types,
                                            while others specialize in creating
                                            excellent customer experiences
                                            across every platform. 
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            One isn’t necessarily better than
                                            the other, but it ultimately depends
                                            on what you’re looking for. 
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            And for the best PPC advertising,
                                            NavUday Solutionis budget-friendly, and with
                                            the guidance of our experts, we have
                                            it all, so you will get each service
                                            in one plate, from copywriting to
                                            videography, from Advertisement to
                                            marketing. 
                                          </span>
                                        </p>
                                        <p>
                                          <span style={{ fontWeight: "400" }}>
                                            Let’s not wait for more and get in
                                            touch with us!
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                    <div
                      data-vc-full-width="true"
                      data-vc-full-width-init="true"
                      data-vc-stretch-content="true"
                      className="vc_row wpb_row vc_row-fluid testimonials-area"
                      style={{
                        position: "relative",
                        left: "-189.6px",
                        boxSizing: "border-box",
                        width: 1519,
                        maxWidth: 1519,
                      }}
                    >
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_row wpb_row vc_inner vc_row-fluid wewidth">
                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                  <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                      <div className="wpb_wrapper">
                                        <h5>
                                          What <span>Our Client</span> Say's
                                        </h5>
                                        <p>
                                          Serving our clients with top-notch
                                          results is our major priority. For
                                          each expert at Adsdad, our motive is
                                          to build a strong relationship with
                                          our client for equivalent growth and
                                          success.
                                        </p>
                                      </div>
                                    </div>

                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html heading-home">
                                      <div className="wpb_wrapper">
                                        <div
                                          className="white"
                                          style={{
                                            background: "rgba(0,0,0,0)",
                                            border: "solid 0px rgba(0,0,0,0)",
                                            borderRadius: 0,
                                            padding: "0px 0px 0px 0px",
                                          }}
                                        >
                                          <div
                                            id="slider_115"
                                            className="owl-carousel sa_owl_theme autohide-arrows owl-loaded owl-drag"
                                            data-slider-id="slider_115"
                                            style={{ visibility: "visible" }}
                                          >
                                            <div className="owl-stage-outer">
                                              <div
                                                className="owl-stage"
                                                style={{
                                                  transform:
                                                    "translate3d(-1110px, 0px, 0px)",
                                                  transition: "0.2s",
                                                  width: 4440,
                                                }}
                                              >
                                                <div
                                                  className="owl-item cloned"
                                                  style={{ width: 555 }}
                                                >
                                                  <div
                                                    id="slider_115_slide03"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          NavUday SolutionDigital's
                                                          website design
                                                          services are
                                                          top-notch. Their team
                                                          is professional,
                                                          talented and very
                                                          responsive. They
                                                          created a beautiful
                                                          website that perfectly
                                                          represents our brand
                                                          and effectively
                                                          communicates our
                                                          message to our target
                                                          audience. We highly
                                                          recommend them!
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Shubham Chabbra (Sketra)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item cloned"
                                                  style={{ width: 555 }}
                                                >
                                                  <div
                                                    id="slider_115_slide04"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          NavUday SolutionExperts helped
                                                          us take our home decor
                                                          marketplace to the
                                                          next level. Their team
                                                          has been instrumental
                                                          in improving our
                                                          search engine
                                                          rankings, increasing
                                                          our organic traffic
                                                          and driving more
                                                          sales. Their
                                                          professionalism,
                                                          support and expertise
                                                          have been a great
                                                          asset to our business.
                                                          We highly recommend
                                                          them!"
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Manjula (Good Homes
                                                        Company)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item active"
                                                  style={{ width: 555 }}
                                                >
                                                  <div
                                                    id="slider_115_slide01"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          They have been a game
                                                          changer for our
                                                          cleaning services
                                                          business. Their team
                                                          has helped us generate
                                                          qualified leads
                                                          through targeted
                                                          campaigns. Their
                                                          professionalism and
                                                          expertise in lead
                                                          generation have been
                                                          invaluable to our
                                                          business. We highly
                                                          recommend them!"
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Alice Allen(Dirt2Tidy)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item active"
                                                  style={{ width: 555 }}
                                                >
                                                  <div
                                                    id="slider_115_slide02"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          We are extremely
                                                          satisfied with the
                                                          results we have seen
                                                          from NavUday SolutionDigital's
                                                          SEO services. They
                                                          have helped us
                                                          increase our online
                                                          visibility and drive
                                                          more traffic to our
                                                          website. Their team is
                                                          easy to work with and
                                                          always goes above and
                                                          beyond to exceed our
                                                          expectations
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Juka (Juka Organics)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item"
                                                  style={{ width: 555 }}
                                                >
                                                  <div
                                                    id="slider_115_slide03"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          NavUday SolutionDigital's
                                                          website design
                                                          services are
                                                          top-notch. Their team
                                                          is professional,
                                                          talented and very
                                                          responsive. They
                                                          created a beautiful
                                                          website that perfectly
                                                          represents our brand
                                                          and effectively
                                                          communicates our
                                                          message to our target
                                                          audience. We highly
                                                          recommend them!
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Shubham Chabbra (Sketra)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item"
                                                  style={{ width: 555 }}
                                                >
                                                  <div
                                                    id="slider_115_slide04"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          NavUday SolutionExperts helped
                                                          us take our home decor
                                                          marketplace to the
                                                          next level. Their team
                                                          has been instrumental
                                                          in improving our
                                                          search engine
                                                          rankings, increasing
                                                          our organic traffic
                                                          and driving more
                                                          sales. Their
                                                          professionalism,
                                                          support and expertise
                                                          have been a great
                                                          asset to our business.
                                                          We highly recommend
                                                          them!"
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Manjula (Good Homes
                                                        Company)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item cloned"
                                                  style={{ width: 555 }}
                                                >
                                                  <div
                                                    id="slider_115_slide01"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          They have been a game
                                                          changer for our
                                                          cleaning services
                                                          business. Their team
                                                          has helped us generate
                                                          qualified leads
                                                          through targeted
                                                          campaigns. Their
                                                          professionalism and
                                                          expertise in lead
                                                          generation have been
                                                          invaluable to our
                                                          business. We highly
                                                          recommend them!"
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Alice Allen(Dirt2Tidy)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="owl-item cloned"
                                                  style={{ width: 555 }}
                                                >
                                                  <div
                                                    id="slider_115_slide02"
                                                    className="sa_hover_container"
                                                    style={{
                                                      padding: "0% 0%",
                                                      margin: "0px 2%",
                                                    }}
                                                  >
                                                    <div className="review-bx">
                                                      <div className="qutes-tst">
                                                         
                                                      </div>
                                                      <div className="tstpara">
                                                        <p>
                                                          We are extremely
                                                          satisfied with the
                                                          results we have seen
                                                          from NavUday SolutionDigital's
                                                          SEO services. They
                                                          have helped us
                                                          increase our online
                                                          visibility and drive
                                                          more traffic to our
                                                          website. Their team is
                                                          easy to work with and
                                                          always goes above and
                                                          beyond to exceed our
                                                          expectations
                                                        </p>
                                                      </div>
                                                      <div className="strr">
                                                        Juka (Juka Organics)
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star" />{" "}
                                                        <i className="fas fa-star-half-alt" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="owl-nav">
                                              <button
                                                type="button"
                                                role="presentation"
                                                className="owl-prev"
                                              >
                                                Previous
                                              </button>
                                              <button
                                                type="button"
                                                role="presentation"
                                                className="owl-next"
                                              >
                                                Next
                                              </button>
                                            </div>
                                            <div className="owl-dots disabled" />
                                            <div className="owl-thumbs" />
                                          </div>
                                        </div>
      
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="vc_row-full-width vc_clearfix" />
                  </div>
                  {/* .entry-content */}
                </article>
                {/* #post-## */}
              </div>
              {/* #main */}
            </section>
            {/* #primary */}
          </div>
          {/* .row */}
        </div>
        {/* .container */}
      </div>
    </>
  );
}

export default Googleads;
