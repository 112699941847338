import React from 'react'

function Footer() {
  return (
    <>
    
    
  
    
    
    <div className="footertop-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="share-details footsh">
                        <p>Now! Ready to <span>SHARE</span> Your Project Details?</p><a className="popmake-243 pum-trigger see" style={{ cursor: "pointer" }}>Click Here To Get A Call
                            Back</a>
                    </div>
                </div>
                <div className="col-lg-6">

                    <div className="wpcf7 js" id="wpcf7-f125-o3" lang="en-US" dir="ltr">
                        <div className="screen-reader-response">
                            <p role="status" aria-live="polite" aria-atomic="true" />
                            <ul />
                        </div>
                        <form action="/#wpcf7-f125-o3" method="post" className="wpcf7-form init" aria-label="Contact form" noValidate data-status="init">
                            <div style={{ display: "none" }}>
                                <input type="hidden" name="_wpcf7" value="125" />
                                <input type="hidden" name="_wpcf7_version" value="5.9.8" />
                                <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f125-o3" />
                                <input type="hidden" name="_wpcf7_container_post" value="0" />
                                <input type="hidden" name="_wpcf7_posted_data_hash"  />
                            </div>
                            <div className="homepage-area">
                                <h4>Share Your Project Details
                                </h4>
                                <p>Cost Effective & Time Efficient Services
                                </p>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="fld">
                                            <p><span className="wpcf7-form-control-wrap" data-name="text-659"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" placeholder="Full Name"  type="text" name="text-659" /></span><i className="fas fa-user" />
                                            </p>
                                        </div>
                                        <div className="fld">
                                            <p><span className="wpcf7-form-control-wrap" data-name="email-722"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control" aria-required="true" aria-invalid="false" placeholder="Email Address"  type="email" name="email-722" /></span><i className="fas fa-envelope" />
                                            </p>
                                        </div>
                                        <div className="fld">
                                            <p><span className="wpcf7-form-control-wrap" data-name="tel-659"><input size={40} maxLength={400} className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control" aria-required="true" aria-invalid="false" placeholder="Phone Number"  type="tel" name="tel-659" /></span><i className="fas fa-phone-alt" />
                                            </p>
                                        </div>
                                        <div className="fld">
                                            <p><span className="wpcf7-form-control-wrap" data-name="textarea-365"><textarea cols={40} rows={10} maxLength={2000} className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" placeholder="Describe Your Requirement.." name="textarea-365" /></span><i className="fas fa-question" />
                                            </p>
                                        </div>
                                        <div>
                                            <p><input className="wpcf7-form-control wpcf7-submit has-spinner btn btn-primary" type="submit" value="Get Your Free Proposal" /><span className="wpcf7-spinner" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div><input type="hidden" className="wpcf7-pum"  />
                            <div className="wpcf7-response-output" aria-hidden="true" />
                        </form>
                    </div>

                </div>
                <div className="col-lg-6">
                    <div className="up-footer">
                        <h4>Ready to speak with a<br />
                            marketing expert?</h4>
                        <span>Give us a ring</span>
                        <a href="tel:+919990851391">+91 9990851391</a>
                        <ul>
                            <li><i className="fa fa-check" aria-hidden="true" /> Leading Digital Marketing Agency</li>
                            <li><i className="fa fa-check" aria-hidden="true" /> 900+ Websites Launched</li>
                            <li><i className="fa fa-check" aria-hidden="true" /> $100M+ Client Revenue Generated</li>
                            <li><i className="fa fa-check" aria-hidden="true" /> Customized Solutions htmlFor Every Business
                            </li>
                        </ul>

                        <div className="ftp-top">
                            <ul className="crtified">
                                <li><img data-src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                            src="https://adsdad.com/wp-content/uploads/2022/12/iso-certificate.png"/></noscript>
                                </li>
                                <li><img data-src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                            src="https://adsdad.com/wp-content/uploads/2022/12/shopify-partner.png"/></noscript>
                                </li>
                                <li><img data-src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                            src="https://adsdad.com/wp-content/uploads/2022/12/godaddy-pro.png"/></noscript>
                                </li>
                                <li><img data-src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                            src="https://adsdad.com/wp-content/uploads/2022/12/meta-business-partner-img.png"/></noscript>
                                </li>
                                <li><img data-src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png" className=" ls-is-cached lazyloaded" style={{ SmushPlaceholderWidth: 222, SmushPlaceholderAspectRatio: "222/81" }} /><noscript><img
                                            src="https://adsdad.com/wp-content/uploads/2022/12/google-partner.png"/></noscript>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footerbottom">
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                    <div className="logoab">
                        <img data-src="" alt='company logo' src="" className=" lazyloaded" style={{ SmushPlaceholderWidth: 210, SmushPlaceholderAspectRatio: "210/57" , color:"white"}} /><noscript><img
                                src=""/></noscript>
                        <p>Leading digital marketing agency helps you to build brand, generate leads, improve ranking,
                            increase sales, design & develop website and market your business.</p>
                        <ul className="footer-social-icons">
                            <li><a href="" target="_blank"><i className="fab fa-facebook-f" /></a></li>
                            <li><a href="" target="_blank"><i className="fab fa-twitter" /></a></li>
                            <li><a href="" target="_blank"><i className="fab fa-linkedin-in" /></a></li>
                            <li><a href="" target="_blank"><i className="fab fa-instagram" /></a></li>
                            <li><a href="" target="_blank"><i className="fab fa-skype" /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="linkarea">
                        <h5>Quick Links</h5>
                        <ul>
                            <li><a href="https://navudaysolution.dhancash.in/">Home</a></li>
                            <li><a href="https://navudaysolution.dhancash.in/about">About Us</a></li>
                            { /* 	<li><a href="#">Services</a></li> */ }
                            <li><a href="https://navudaysolution.dhancash.in/contact">Contact Us</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="linkarea">
                        <h5>Services</h5>
                        <ul>
                            <li><a href="https://navudaysolution.dhancash.in/ppc">PPC Services</a></li>
                            <li><a href="https://navudaysolution.dhancash.in/seopage">SEO Services</a></li>
                            <li><a href="https://navudaysolution.dhancash.in/brandbuild">Brand Building</a></li>
                            <li><a href="https://navudaysolution.dhancash.in/socialmedia">Social Media Marketing</a></li>
                            <li><a href="https://navudaysolution.dhancash.in/webdesign">Web Design & Development</a></li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="linkarea cot">
                        <h5>Contact</h5>
                        <ul className="info">
                            <li><i className="fas fa-phone-alt" /> <a href="tel:+918789522578">+91 9990851391</a></li>
                            <li><i className="fa-brands fa-whatsapp" /> <a href="https://api.whatsapp.com/send?phone=919990851391">+91 9990851391</a></li>
                            <li><i className="fa-brands fa-skype" /> <a href="#">Skype</a></li>
                            <li><i className="fas fa-envelope-open-text" /> <a href="mailto:contact@NavUdaysolution.com">contact@navudaysolution.com</a></li>
                            <li><i className="fas fa-map-marker-alt" /> <a href="#" target="_blank">Noida-Delhi NCR, India</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="sticky-social deskshow">
            <ul className="social">
                <li className="whatsapp">
                    <a href="https://api.whatsapp.com/send?phone=919990851391" target="_blank" rel="noopener"><img className="pulse-1 lazyloaded" data-src="https://adsdad.com/wp-content/uploads/2023/02/whatsapp-right-area.png" alt="whatsapp-button" src="https://adsdad.com/wp-content/uploads/2023/02/whatsapp-right-area.png" style={{ SmushPlaceholderWidth: 64, SmushPlaceholderAspectRatio: "64/64" }} /><noscript><img
                                className="pulse-1"
                                src="https://adsdad.com/wp-content/uploads/2023/02/whatsapp-right-area.png"
                                alt="whatsapp-button"/></noscript></a>
                </li>
                <li className="call">
                    <a href="#" target="_blank" rel="noopener"><img className="pulse-1 lazyloaded" data-src="https://adsdad.com/wp-content/uploads/2023/02/skype-left-area.png" alt="call-button" src="https://adsdad.com/wp-content/uploads/2023/02/skype-left-area.png" style={{ SmushPlaceholderWidth: 64, SmushPlaceholderAspectRatio: "64/64" }} /><noscript><img
                                class="pulse-1" src="https://adsdad.com/wp-content/uploads/2023/02/skype-left-area.png"
                                alt="call-button"/></noscript></a>
                </li>
            </ul>
        </div>
    </footer>

    <div className="copy-bot">
        <div className="container">
            <div className="row">
                <div className="copy-bott">
                    <p>© 2023. Adsdad Digital - Best Digital Marketing Company. All Rights Reserved</p>
                </div>
            </div>
        </div>
    </div>

    <ul className="helpstick mobshow">
        <li>
            <a href="tel:+919990851391">
                <span><i className="fas fa-phone-alt" /> Call</span>
            </a>
        </li>
        <li>
            <a target="blank" href="https://api.whatsapp.com/send?phone=919990851391">
                <span><i className="fab fa-whatsapp" /> Whatsapp</span>
            </a>
        </li>
    </ul>
    
    
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default Footer
